import { DeactivatedByBalance } from "@/components/alerts/DeactivatedByBalance"
import { HelpButton } from "@/components/buttons/HelpButton"
import { AppsAccordion } from "@/components/data/AppsAccordion"
import { ManualConnection } from "@/components/data/connect/ManualConnection"
import {
  ANDROID_APPS,
  IOS_APPS,
  MACOS_APPS,
  WINDOWS_APPS,
} from "@/services/apps/apps"
import { useTitle } from "@/stores/page"
import { Alert, Space, Title } from "@mantine/core"
import { useShallowEffect } from "@mantine/hooks"
import { useSuspenseQuery } from "@tanstack/react-query"
import { createFileRoute } from "@tanstack/react-router"
import { zodSearchValidator } from "@tanstack/router-zod-adapter"
import { useClosingBehavior } from "@telegram-apps/sdk-react"
import { getInboundsControllerFindOneQueryOptions } from "@vpnox/sdk"
import { useEffect, useMemo } from "react"
import { z } from "zod"

const connectionSearchSchema = z.object({
  os: z.enum(["ios", "android", "windows", "macos", "manual"]),
})

export const Route = createFileRoute("/_page/devices/$id/connection")({
  loader: ({ context: { queryClient }, params: { id } }) => {
    queryClient.ensureQueryData(getInboundsControllerFindOneQueryOptions(id))
  },
  validateSearch: zodSearchValidator(connectionSearchSchema),
  component: DevicesIdConnectionComponent,
})

const text: Record<
  z.infer<typeof connectionSearchSchema>["os"],
  {
    title: string
    description: string
  }
> = {
  ios: {
    title: "Доступные приложения для iOS",
    description:
      "Выберите приложение из доступных ниже, чтобы подключить устройство",
  },
  macos: {
    title: "Доступные программы для macOS",
    description:
      "Выберите программу из доступных ниже, чтобы подключить устройство",
  },
  android: {
    title: "Доступные приложения для Android",
    description:
      "Выберите приложение из доступных ниже, чтобы подключить устройство",
  },
  windows: {
    title: "Доступные программы для Windows",
    description:
      "Выберите программу из доступных ниже, чтобы подключить устройство",
  },
  manual: {
    title: "Ручное подключение",
    description:
      "Подойдет если вашего приложения нет в списке или у устройства нет доступа к вашему телеграм аккаунту. Мы рекомендуем использовать этот метод если вы понимаете что вы делаете.",
  },
}

function DevicesIdConnectionComponent() {
  const { id } = Route.useParams()
  const { os } = Route.useSearch()
  const { data: inbound, isLoading } = useSuspenseQuery(
    getInboundsControllerFindOneQueryOptions(id),
  )
  const { setTitle } = useTitle()
  const { title, description } = text[os]
  const closingBehavior = useClosingBehavior()

  useEffect(() => {
    closingBehavior.enableConfirmation()

    return () => closingBehavior.disableConfirmation()
  }, [closingBehavior])

  const apps = useMemo(() => {
    switch (os) {
      case "ios":
        return IOS_APPS

      case "macos":
        return MACOS_APPS

      case "android":
        return ANDROID_APPS

      case "windows":
        return WINDOWS_APPS

      case "manual":
        return null

      default:
        throw new Error("Unknown OS")
    }
  }, [os])

  useShallowEffect(() => {
    setTitle(isLoading ? "Загрузка..." : inbound.name)
  }, [setTitle, inbound])

  return (
    <>
      <DeactivatedByBalance />
      <Title order={2}>{title}</Title>
      <Alert>{description}</Alert>

      {inbound.external && (
        <>
          {apps && <AppsAccordion apps={apps} inbound={inbound.external} />}
          {os === "manual" && <ManualConnection inbound={inbound.external} />}
        </>
      )}

      <Space />
      <HelpButton mt="auto" />
    </>
  )
}
