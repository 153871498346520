import { RowOption } from "@/components/RowOption"
import { DeactivatedByBalance } from "@/components/alerts/DeactivatedByBalance"
import { HelpButton } from "@/components/buttons/HelpButton"
import { BasicCard } from "@/components/shared/BasicCard"
import { IconBlock } from "@/components/shared/IconBlock"
import { IconAndroid, IconApple, IconWindows } from "@/services/apps/apps"
import { useTitle } from "@/stores/page"
import { Alert, Title } from "@mantine/core"
import { useShallowEffect } from "@mantine/hooks"
import { IconAppsFilled } from "@tabler/icons-react"
import { useSuspenseQuery } from "@tanstack/react-query"
import { Outlet, createFileRoute } from "@tanstack/react-router"
import { getInboundsControllerFindOneQueryOptions } from "@vpnox/sdk"

export const Route = createFileRoute("/_device/devices/$id")({
  loader: ({ context: { queryClient }, params: { id } }) => {
    queryClient.ensureQueryData(getInboundsControllerFindOneQueryOptions(id))
  },
  component: DevicesIdComponent,
})

const iconProps = {
  style: {
    width: 18,
    height: 18,
  },
}

function DevicesIdComponent() {
  const { id } = Route.useParams()
  const { data: inbound, isLoading } = useSuspenseQuery(
    getInboundsControllerFindOneQueryOptions(id),
  )
  const { setTitle } = useTitle()

  useShallowEffect(() => {
    setTitle(isLoading ? "Загрузка..." : inbound.name)
  }, [setTitle, inbound])

  return (
    <>
      <DeactivatedByBalance />
      <Title order={2}>Подключение</Title>
      <Alert>
        Для начала выберите операцонную систему, которую вы используете
      </Alert>
      <BasicCard py={4}>
        <RowOption
          label="iOS"
          prefix={<IconBlock icon={<IconApple {...iconProps} />} />}
          href={"/devices/$id/connection"}
          search={{ os: "ios" }}
        />
        <RowOption
          label="Android"
          prefix={<IconBlock icon={<IconAndroid {...iconProps} />} />}
          href={"/devices/$id/connection"}
          search={{ os: "android" }}
        />
        <RowOption
          label="Windows"
          prefix={<IconBlock icon={<IconWindows {...iconProps} />} />}
          href={"/devices/$id/connection"}
          search={{ os: "windows" }}
        />
        <RowOption
          label="Mac OS"
          prefix={<IconBlock icon={<IconApple {...iconProps} />} />}
          href={"/devices/$id/connection"}
          search={{ os: "macos" }}
        />
      </BasicCard>
      <BasicCard py={4}>
        <RowOption
          label="Ручное подключение"
          prefix={<IconBlock icon={<IconAppsFilled {...iconProps} />} />}
          href={"/devices/$id/connection"}
          search={{ os: "manual" }}
        />
      </BasicCard>
      <HelpButton mt="auto" />
      <Outlet />
    </>
  )
}
