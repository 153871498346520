/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * VPNoX API
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery, useSuspenseQuery } from "@tanstack/react-query"
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from "@tanstack/react-query"
import { customInstance } from "../../client"
import type { BodyType, ErrorType } from "../../client"
import type {
  GenerateInvoiceDto,
  PaymentSucceedDto,
  ResponseGenerateInvoiceDto,
  ResponsePaymentStatusDto,
} from "../../model"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

export const paymentsControllerGenerateInvoice = (
  generateInvoiceDto: BodyType<GenerateInvoiceDto>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ResponseGenerateInvoiceDto>(
    {
      url: "/api/payments/invoice",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: generateInvoiceDto,
    },
    options,
  )
}

export const getPaymentsControllerGenerateInvoiceMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof paymentsControllerGenerateInvoice>>,
    TError,
    { data: BodyType<GenerateInvoiceDto> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof paymentsControllerGenerateInvoice>>,
  TError,
  { data: BodyType<GenerateInvoiceDto> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof paymentsControllerGenerateInvoice>>,
    { data: BodyType<GenerateInvoiceDto> }
  > = props => {
    const { data } = props ?? {}

    return paymentsControllerGenerateInvoice(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PaymentsControllerGenerateInvoiceMutationResult = NonNullable<
  Awaited<ReturnType<typeof paymentsControllerGenerateInvoice>>
>
export type PaymentsControllerGenerateInvoiceMutationBody =
  BodyType<GenerateInvoiceDto>
export type PaymentsControllerGenerateInvoiceMutationError = ErrorType<unknown>

export const usePaymentsControllerGenerateInvoice = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof paymentsControllerGenerateInvoice>>,
    TError,
    { data: BodyType<GenerateInvoiceDto> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof paymentsControllerGenerateInvoice>>,
  TError,
  { data: BodyType<GenerateInvoiceDto> },
  TContext
> => {
  const mutationOptions =
    getPaymentsControllerGenerateInvoiceMutationOptions(options)

  return useMutation(mutationOptions)
}
export const paymentsControllerNotifications = (
  paymentSucceedDto: BodyType<PaymentSucceedDto>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<boolean>(
    {
      url: "/api/payments/notifications",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: paymentSucceedDto,
    },
    options,
  )
}

export const getPaymentsControllerNotificationsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof paymentsControllerNotifications>>,
    TError,
    { data: BodyType<PaymentSucceedDto> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof paymentsControllerNotifications>>,
  TError,
  { data: BodyType<PaymentSucceedDto> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof paymentsControllerNotifications>>,
    { data: BodyType<PaymentSucceedDto> }
  > = props => {
    const { data } = props ?? {}

    return paymentsControllerNotifications(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PaymentsControllerNotificationsMutationResult = NonNullable<
  Awaited<ReturnType<typeof paymentsControllerNotifications>>
>
export type PaymentsControllerNotificationsMutationBody =
  BodyType<PaymentSucceedDto>
export type PaymentsControllerNotificationsMutationError = ErrorType<unknown>

export const usePaymentsControllerNotifications = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof paymentsControllerNotifications>>,
    TError,
    { data: BodyType<PaymentSucceedDto> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof paymentsControllerNotifications>>,
  TError,
  { data: BodyType<PaymentSucceedDto> },
  TContext
> => {
  const mutationOptions =
    getPaymentsControllerNotificationsMutationOptions(options)

  return useMutation(mutationOptions)
}
export const paymentsControllerValidatePayment = (
  id: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ResponsePaymentStatusDto>(
    { url: `/api/payments/status/${id}`, method: "GET", signal },
    options,
  )
}

export const getPaymentsControllerValidatePaymentQueryKey = (id: string) => {
  return [`/api/payments/status/${id}`] as const
}

export const getPaymentsControllerValidatePaymentQueryOptions = <
  TData = Awaited<ReturnType<typeof paymentsControllerValidatePayment>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof paymentsControllerValidatePayment>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getPaymentsControllerValidatePaymentQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof paymentsControllerValidatePayment>>
  > = ({ signal }) =>
    paymentsControllerValidatePayment(id, requestOptions, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    staleTime: 300000,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof paymentsControllerValidatePayment>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type PaymentsControllerValidatePaymentQueryResult = NonNullable<
  Awaited<ReturnType<typeof paymentsControllerValidatePayment>>
>
export type PaymentsControllerValidatePaymentQueryError = ErrorType<unknown>

export function usePaymentsControllerValidatePayment<
  TData = Awaited<ReturnType<typeof paymentsControllerValidatePayment>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof paymentsControllerValidatePayment>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getPaymentsControllerValidatePaymentQueryOptions(
    id,
    options,
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getPaymentsControllerValidatePaymentSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof paymentsControllerValidatePayment>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof paymentsControllerValidatePayment>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getPaymentsControllerValidatePaymentQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof paymentsControllerValidatePayment>>
  > = ({ signal }) =>
    paymentsControllerValidatePayment(id, requestOptions, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    staleTime: 300000,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof paymentsControllerValidatePayment>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type PaymentsControllerValidatePaymentSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof paymentsControllerValidatePayment>>
>
export type PaymentsControllerValidatePaymentSuspenseQueryError =
  ErrorType<unknown>

export function usePaymentsControllerValidatePaymentSuspense<
  TData = Awaited<ReturnType<typeof paymentsControllerValidatePayment>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof paymentsControllerValidatePayment>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getPaymentsControllerValidatePaymentSuspenseQueryOptions(
    id,
    options,
  )

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}
