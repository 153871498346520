import { useAlertStore } from "@/stores/alert"
import { plural } from "@/utils/plural"
import { Alert } from "@mantine/core"
import { IconAlertCircle } from "@tabler/icons-react"

type RemainingDaysProps = {
  days: number
}

export const RemainingDays: React.FC<RemainingDaysProps> = ({ days }) => {
  const { checkLastDateBalanceClosedActive, setLastDateBalanceClosed } =
    useAlertStore()
  const remainingDays = plural(
    days,
    n => `Остался ${n} день подписки`,
    n => `Осталось ${n} дня подписки`,
    n => `Осталось ${n} дней подписки`,
  )

  const isActive = checkLastDateBalanceClosedActive(days)

  if (!isActive) {
    return null
  }

  return (
    <Alert
      variant="light"
      color="orange"
      title={remainingDays}
      icon={<IconAlertCircle />}
      withCloseButton
      onClose={() => setLastDateBalanceClosed()}
    >
      Рекомендуем пополнить баланс, чтобы избежать отключения услуг
    </Alert>
  )
}
