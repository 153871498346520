import { Box, Loader, useMantineColorScheme } from "@mantine/core"
import { IconReload } from "@tabler/icons-react"
import { useSwipeBehavior } from "@telegram-apps/sdk-react"
import type { PropsWithChildren } from "react"
import { usePullToRefresh } from "use-pull-to-refresh"

const MAXIMUM_PULL_LENGTH = 240
const REFRESH_THRESHOLD = 180

type RefreshLayoutProps = PropsWithChildren & {
  onRefreshRequested: () => void
}

export const RefreshLayout: React.FC<RefreshLayoutProps> = ({
  children,
  onRefreshRequested,
}) => {
  const swipeBehavior = useSwipeBehavior()
  const supportSwipeBehavior = swipeBehavior.supports("disableVerticalSwipe")

  const { isRefreshing, pullPosition } = usePullToRefresh({
    // you can choose what behavior for `onRefresh`, could be calling an API to load more data, or refresh whole page.
    onRefresh: onRefreshRequested,
    maximumPullLength: MAXIMUM_PULL_LENGTH,
    refreshThreshold: REFRESH_THRESHOLD,
    isDisabled: !supportSwipeBehavior,
  })
  const { colorScheme } = useMantineColorScheme()

  return (
    <>
      <Box
        style={theme => ({
          top: (isRefreshing ? REFRESH_THRESHOLD : pullPosition) / 3,
          opacity: isRefreshing || pullPosition > 0 ? 1 : 0,
          zIndex: 90,
          width: 40,
          height: 40,
          borderRadius: 20,
          background:
            colorScheme === "light"
              ? theme.colors.gray[2]
              : theme.colors.dark[9],
          left: "50%",
          position: "fixed",
          transform: "translateX(-50%)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: theme.shadows.lg,
          color:
            colorScheme === "light"
              ? theme.colors.gray[6]
              : theme.colors.gray[6],
        })}
      >
        {isRefreshing ? (
          <Loader size="xs" />
        ) : (
          <IconReload
            size={20}
            stroke={2}
            style={
              !isRefreshing ? { transform: `rotate(${pullPosition}deg)` } : {}
            }
          />
        )}
      </Box>
      {children}
    </>
  )
}
