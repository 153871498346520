import { RowOption } from "@/components/RowOption"
import {
  BasicCard,
  type BasicCardProps,
} from "@/components/shared/BasicCard/BasicCard"
import { IconBlock } from "@/components/shared/IconBlock"
import { IconHelpCircleFilled } from "@tabler/icons-react"

export const HelpButton = (props: BasicCardProps) => {
  return (
    <BasicCard py={4} {...props}>
      <RowOption
        prefix={<IconBlock icon={<IconHelpCircleFilled size={22} />} />}
        label={"Помощь и чат"}
        description={"Служба личной поддержки"}
        href={"/help"}
      />
    </BasicCard>
  )
}
