import { BalanceBonus } from "@/components/forms/BalanceBonus"
import { RadioCard } from "@/components/inputs/RadioCard"
import { BasicCard } from "@/components/shared/BasicCard"
import { DATE_FORMAT } from "@/utils/date"
import { findNextDivisibleByFour } from "@/utils/money"
import { plural } from "@/utils/plural"
import {
  Alert,
  Badge,
  Box,
  Button,
  Grid,
  NumberFormatter,
  NumberInput,
  Radio,
  Space,
  Stack,
  Text,
  Title,
  useMantineColorScheme,
} from "@mantine/core"
import { useForm } from "@mantine/form"
import { useDebouncedCallback } from "@mantine/hooks"
import dayjs from "dayjs"
import { zodResolver } from "mantine-form-zod-resolver"
import type React from "react"
import { useState } from "react"
import { z } from "zod"

const BASE_PRICE = 4
const MONTH_PRICE = BASE_PRICE * 30
const MINIMUM_AMOUNT = 20

const data = [
  {
    value: MONTH_PRICE,
    description: "1 месяц",
    bonus: null,
    row: 6,
  },
  // {
  //   value: MONTH_PRICE * 2,
  //   description: "2 месяца",
  //   bonus: null,
  //   row: 6,
  // },
  {
    value: MONTH_PRICE * 3,
    description: "3 месяца",
    bonus: null,
    row: 6,
  },
  // {
  //   value: MONTH_PRICE * 4,
  //   description: "4 месяца",
  //   bonus: null,
  //   row: 6,
  // },
  {
    value: MONTH_PRICE * 6,
    description: "6 месяцев",
    bonus: "+15%",
    // bonus: null,
    row: 6,
  },
  {
    value: MONTH_PRICE * 12,
    description: "12 месяцев",
    bonus: "+20%",
    // bonus: null,
    row: 6,
  },
  {
    value: 0,
    description: "Произвольная сумма",
    bonus: null,
    row: 12,
  },
]

const values = data.map(item => item.value)

const paymentFormSchema = z.object({
  amount: z.coerce
    .number({
      invalid_type_error: "Выберите сумму пополнения, чтобы продолжить",
    })
    .min(4, {
      message: `Минимальная сумма пополнения ${MINIMUM_AMOUNT} рублей`,
    }),
})

export type PaymentFormValues = z.infer<typeof paymentFormSchema>

type PaymentFormProps = {
  onSubmit: (values: PaymentFormValues) => void
  remainingDays: number
  loading?: boolean
}

export const PaymentForm: React.FC<PaymentFormProps> = ({
  onSubmit,
  loading = false,
  ...props
}) => {
  const { colorScheme } = useMantineColorScheme()
  const [option, setOption] = useState(120)
  const formUpdate = useDebouncedCallback((amount: number) => {
    values.includes(amount) ? setOption(amount) : setOption(0)
  }, 300)

  const form = useForm<PaymentFormValues>({
    mode: "controlled",
    initialValues: {
      amount: option,
    },
    validate: zodResolver(paymentFormSchema),
    onValuesChange: ({ amount }) => {
      formUpdate(amount)
    },
  })

  const amountField = form.getInputProps("amount")
  const cards = data.map(item => (
    <Grid.Col key={item.value} span={item.row}>
      <RadioCard value={`${item.value}`}>
        <Stack
          gap={0}
          style={{
            flex: 1,
            lineHeight: 1.25,
          }}
        >
          {item.value > 0 && (
            <NumberFormatter
              suffix=" ₽"
              value={item.value}
              thousandSeparator=" "
              style={{
                fontSize: 18,
                fontWeight: 700,
              }}
            />
          )}
          {item.bonus && (
            <Badge
              color="pink"
              px={6}
              style={{
                height: 24,
                position: "absolute",
                top: -8,
                right: -5,
                fontWeight: 400,
                fontSize: 13,
                display: "flex",
                alignItems: "center",
                borderRadius: 10,
                borderWidth: 3,
                borderStyle: "solid",
                borderColor:
                  colorScheme === "light"
                    ? "var(--mantine-color-gray-0)"
                    : "var(--mantine-color-dark-8)",
              }}
            >
              {item.bonus}
            </Badge>
          )}
          {!!item.description && <Text size="sm">{item.description}</Text>}
        </Stack>
      </RadioCard>
    </Grid.Col>
  ))
  const sum =
    amountField.value >= 1000
      ? findNextDivisibleByFour(amountField.value * 1.2)
      : amountField.value >= 500
        ? findNextDivisibleByFour(amountField.value * 1.15)
        : amountField.value
  const days = Math.floor(
    amountField.value >= MINIMUM_AMOUNT ? sum / BASE_PRICE : 0,
  )

  const remainingDays = plural(
    days,
    n =>
      `Хватит на ${n} день, до ${dayjs()
        .add(n + props.remainingDays, "day")
        .format(DATE_FORMAT)}`,
    n =>
      `Хватит на ${n} дня, до ${dayjs()
        .add(n + props.remainingDays, "day")
        .format(DATE_FORMAT)}`,
    n =>
      `Хватит на ${n} дней, до ${dayjs()
        .add(n + props.remainingDays, "day")
        .format(DATE_FORMAT)}`,
    `Минимальная сумма пополнения ${MINIMUM_AMOUNT} рублей`,
  )

  return (
    <Box
      component={"form"}
      onSubmit={form.onSubmit(onSubmit)}
      style={{ flex: 1, display: "flex", flexDirection: "column" }}
    >
      <Stack>
        <Alert>
          Вы можете выбрать сумму или ввести ее произвольно в поле вручную
        </Alert>

        <Box>
          <Title order={3}>Сумма пополнения</Title>
          <NumberInput
            {...amountField}
            thousandSeparator=" "
            step={10}
            leftSection={
              <Text fz={24} fw={500}>
                ₽
              </Text>
            }
            rightSection={null}
            hideControls
            styles={{
              input: {
                height: 50,
                fontSize: 24,
              },
            }}
          />
        </Box>

        {!!remainingDays && (
          <Text size="sm" c="dimmed">
            {remainingDays}
          </Text>
        )}

        <Radio.Group
          value={option.toString()}
          onChange={value => {
            const amount = Number.parseInt(value)
            form.setFieldValue(
              "amount",
              !(Number.isNaN(amount) || amount === 0) ? amount : 100,
            )
            setOption(!Number.isNaN(amount) ? amount : 0)
          }}
        >
          <BasicCard p="xs">
            <Grid gutter={6} grow>
              {cards}
            </Grid>
          </BasicCard>
        </Radio.Group>

        <BalanceBonus value={amountField.value} color="pink" />
      </Stack>

      <Stack mt="auto" gap="xs">
        {amountField.value >= 500 ? (
          <Text c="dimmed" ta="center" size="sm">
            С бонусом будет начислено{" "}
            <NumberFormatter
              suffix=" ₽"
              value={sum ?? 0}
              thousandSeparator=" "
            />{" "}
            (+
            <NumberFormatter
              suffix=" ₽"
              value={sum - amountField.value}
              thousandSeparator=" "
            />
            )
          </Text>
        ) : (
          <Space h={20} />
        )}
        <Button type="submit" loading={loading}>
          Перейти к оплате
        </Button>
      </Stack>
    </Box>
  )
}
