/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * VPNoX API
 * OpenAPI spec version: 1.0
 */
import { useQuery, useSuspenseQuery } from "@tanstack/react-query"
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from "@tanstack/react-query"
import { customInstance } from "../../client"
import type { ErrorType } from "../../client"
import type { BalanceTransactionsControllerFindAll200AllOf } from "../../model"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

export const balanceTransactionsControllerFindAll = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<BalanceTransactionsControllerFindAll200AllOf>(
    { url: "/api/transactions", method: "GET", signal },
    options,
  )
}

export const getBalanceTransactionsControllerFindAllQueryKey = () => {
  return ["/api/transactions"] as const
}

export const getBalanceTransactionsControllerFindAllQueryOptions = <
  TData = Awaited<ReturnType<typeof balanceTransactionsControllerFindAll>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof balanceTransactionsControllerFindAll>>,
    TError,
    TData
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getBalanceTransactionsControllerFindAllQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof balanceTransactionsControllerFindAll>>
  > = ({ signal }) =>
    balanceTransactionsControllerFindAll(requestOptions, signal)

  return {
    queryKey,
    queryFn,
    staleTime: 300000,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof balanceTransactionsControllerFindAll>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type BalanceTransactionsControllerFindAllQueryResult = NonNullable<
  Awaited<ReturnType<typeof balanceTransactionsControllerFindAll>>
>
export type BalanceTransactionsControllerFindAllQueryError = ErrorType<unknown>

export function useBalanceTransactionsControllerFindAll<
  TData = Awaited<ReturnType<typeof balanceTransactionsControllerFindAll>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof balanceTransactionsControllerFindAll>>,
    TError,
    TData
  >
  request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getBalanceTransactionsControllerFindAllQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getBalanceTransactionsControllerFindAllSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof balanceTransactionsControllerFindAll>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof balanceTransactionsControllerFindAll>>,
    TError,
    TData
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getBalanceTransactionsControllerFindAllQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof balanceTransactionsControllerFindAll>>
  > = ({ signal }) =>
    balanceTransactionsControllerFindAll(requestOptions, signal)

  return {
    queryKey,
    queryFn,
    staleTime: 300000,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof balanceTransactionsControllerFindAll>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type BalanceTransactionsControllerFindAllSuspenseQueryResult =
  NonNullable<Awaited<ReturnType<typeof balanceTransactionsControllerFindAll>>>
export type BalanceTransactionsControllerFindAllSuspenseQueryError =
  ErrorType<unknown>

export function useBalanceTransactionsControllerFindAllSuspense<
  TData = Awaited<ReturnType<typeof balanceTransactionsControllerFindAll>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof balanceTransactionsControllerFindAll>>,
    TError,
    TData
  >
  request?: SecondParameter<typeof customInstance>
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getBalanceTransactionsControllerFindAllSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}
