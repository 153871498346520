import { Card, RadioCard } from "@mantine/core"

import classes from "./card.module.css"

export default {
  Card: Card.extend({
    classNames: classes,
    defaultProps: {
      radius: "md",
      withBorder: false,
    },
  }),
  RadioCard: RadioCard.extend({
    classNames: classes,
    defaultProps: {
      radius: "md",
      withBorder: false,
    },
  }),
}
