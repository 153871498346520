import { Progress } from "@mantine/core"

import classes from "./progress.module.css"

export default {
  Progress: Progress.extend({
    classNames: classes,
    defaultProps: {
      size: "sm",
    },
  }),
}
