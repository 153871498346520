import {
  DeviceEditForm,
  type DeviceEditFormValues,
} from "@/components/forms/DeviceEditForm"
import { useTitle } from "@/stores/page"
import { useNotifications } from "@/utils/hooks/use-notifications"
import { useShallowEffect } from "@mantine/hooks"
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query"
import { createFileRoute } from "@tanstack/react-router"
import { useClosingBehavior } from "@telegram-apps/sdk-react"
import type { InboundEntity } from "@vpnox/sdk"
import {
  getInboundsControllerFindAllQueryKey,
  getInboundsControllerFindOneQueryKey,
  getInboundsControllerFindOneQueryOptions,
  useInboundsControllerUpdate,
} from "@vpnox/sdk"
import React, { useEffect } from "react"

export const Route = createFileRoute("/_page/devices/$id/edit")({
  loader: ({ context: { queryClient }, params: { id } }) => {
    queryClient.ensureQueryData(getInboundsControllerFindOneQueryOptions(id))
  },
  component: DevicesIdEditComponent,
})

function DevicesIdEditComponent() {
  const navigate = Route.useNavigate()
  const { id } = Route.useParams()
  const { setTitle } = useTitle()
  const { data: inbound, isLoading } = useSuspenseQuery(
    getInboundsControllerFindOneQueryOptions(id),
  )
  const { showSuccessMessage } = useNotifications()
  const closingBehavior = useClosingBehavior()

  useEffect(() => {
    closingBehavior.enableConfirmation()

    return () => closingBehavior.disableConfirmation()
  }, [closingBehavior])

  const queryClient = useQueryClient()
  const { mutateAsync: updateInbound, isPending } = useInboundsControllerUpdate(
    {
      mutation: {
        async onSuccess(newInbound: InboundEntity) {
          // update the cache with the new inbound
          // queryClient.setQueryData(
          //   getInboundsControllerFindOneQueryKey(newInbound.id),
          //   newInbound,
          // )
          await queryClient.invalidateQueries({
            queryKey: getInboundsControllerFindOneQueryKey(newInbound.id),
          })

          // invalidate all the list queries
          await queryClient.invalidateQueries({
            queryKey: getInboundsControllerFindAllQueryKey(),
          })

          showSuccessMessage({
            title: "Выполнено",
            message: "Устройство успешно обновлено",
          })
        },
      },
    },
  )

  const handleSubmit = async (values: DeviceEditFormValues) => {
    try {
      await updateInbound({ id, data: values })
      await navigate({
        to: "/devices/$id",
        replace: true,
        resetScroll: true,
      })
    } catch (error) {
      // TODO: handle error
      console.error(error)
    }
  }

  useShallowEffect(() => {
    setTitle(isLoading ? "Загрузка..." : inbound.name)
  }, [setTitle])

  return (
    <DeviceEditForm
      onSubmit={handleSubmit}
      defaultValues={{
        name: inbound.name,
      }}
      loading={isPending}
    />
  )
}
