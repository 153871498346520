import { BasicCard } from "@/components/shared/BasicCard"
import { Stack } from "@mantine/core"

import classes from "./MainContent.module.css"

type MainContentProps = {
  children: React.ReactNode
}

export const MainContent = ({ children }: MainContentProps) => {
  return (
    <BasicCard depth="xs" radius="lg" className={classes.main}>
      <Stack component="main" className={classes.stack}>
        {children}
      </Stack>
    </BasicCard>
  )
}
