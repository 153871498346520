import { DeviceDashboard } from "@/components/data/DeviceDashboard"
import { MainContent } from "@/components/layouts/MainContent"
import { Outlet, createFileRoute } from "@tanstack/react-router"

export const Route = createFileRoute("/_device")({
  component: DevicesComponent,
})

function DevicesComponent() {
  return (
    <>
      <DeviceDashboard />

      <MainContent>
        <Outlet />
      </MainContent>
    </>
  )
}
