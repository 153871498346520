import { Alert } from "@mantine/core"

import { IconInfoCircle } from "@tabler/icons-react"
import classes from "./alert.module.css"

export default {
  Alert: Alert.extend({
    defaultProps: {
      variant: "light",
      radius: "md",
      classNames: classes,
      icon: <IconInfoCircle />,
    },
  }),
}
