import { Anchor, Image, Title } from "@mantine/core"
import type { MDXComponents } from "mdx/types"
import { MdxErrorMessage } from "../MdxErrorMessage/MdxErrorMessage"
import { MdxInfo } from "../MdxInfo/MdxInfo"

import classes from "./MdxElements.module.css"

export function MdxTitle({
  id,
  children,
  order = 2,
  ...others
}: React.ComponentPropsWithoutRef<typeof Title>) {
  return (
    <Title order={order} className={classes.title} {...others}>
      {children}
    </Title>
  )
}

export const h = (order: 1 | 2 | 3 | 4 | 5 | 6) => (props: any) => (
  <MdxTitle order={order} {...props} />
)

export function MdxParagraph(props: React.ComponentPropsWithoutRef<"p">) {
  return <p className={classes.paragraph} {...props} />
}

export function MdxUl(props: React.ComponentPropsWithoutRef<"ul">) {
  return <ul className={classes.ul} {...props} />
}

export function MdxLi(props: React.ComponentPropsWithoutRef<"li">) {
  return <li className={classes.li} {...props} />
}

export function MdxLink({
  href,
  ...others
}: React.ComponentPropsWithoutRef<"a">) {
  return <Anchor className={classes.link} href={href} {...others} />
}

export function useMDXComponents(components?: MDXComponents): MDXComponents {
  return {
    img: Image as any,
    ul: MdxUl,
    li: MdxLi,
    p: MdxParagraph,
    a: MdxLink,
    h1: h(1),
    h2: h(2),
    h3: h(3),
    h4: h(4),
    h5: h(5),
    h6: h(6),
    ErrorMessage: MdxErrorMessage,
    blockquote: MdxInfo,
    ...components,
  }
}
