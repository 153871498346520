import "@fontsource-variable/onest"
import "@mantine/core/styles.css"
import "@mantine/notifications/styles.css"

import "@/global.css"

import { App } from "@/app"
import ReactDOM from "react-dom/client"

// Uncomment this import in case, you would like to develop the application even outside
// the Telegram application, just in your browser.
import "./mockEnv"

// Sentry is a service that helps you monitor and fix crashes in real time.
import "./libs/sentry"

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <App />,
)
