import { useTitle } from "@/stores/page"
import { Button, Stack, Text } from "@mantine/core"
import { useShallowEffect } from "@mantine/hooks"
import { createFileRoute } from "@tanstack/react-router"
import { useUtils } from "@telegram-apps/sdk-react"
import { useUsersControllerLoginTelegramSuspense } from "@vpnox/sdk"
import React from "react"

export const Route = createFileRoute("/_page/help")({
  component: HelpComponent,
})

function HelpComponent() {
  const { data: user } = useUsersControllerLoginTelegramSuspense()
  const { setTitle } = useTitle()
  const utils = useUtils()

  useShallowEffect(() => {
    setTitle("Помощь")
  }, [setTitle])

  return (
    <Stack justify="space-between" flex={1}>
      <Stack>
        <Text>
          Если у вас возникли проблемы с подключением или оплатой вы можете
          воспользоваться нашей технической поддержкой.
        </Text>
        <Text>
          Напишите свой вопрос в чат, по возможности максимально развернуто,
          укажите ваше устройство и приложение, с которым у вас проблемы.
        </Text>
        <Text>Мы обязательно ответим на ваш запрос!</Text>
      </Stack>
      <Stack>
        {/* TODO: Admin id | role */}
        {user.telegramId === 283222525 && (
          <Text size="xs" c="dimmed" ta="center" mt={4} p="xs">
            [1.0.0] Release 16.09.2024
          </Text>
        )}
        <Text size="sm" ta="center">
          Спасибо что пользуетесь нашим сервисом
        </Text>
        <Button
          onClick={() =>
            utils.openTelegramLink("https://t.me/vpnox_support_bot")
          }
        >
          @vpnox_support_bot
        </Button>
      </Stack>
    </Stack>
  )
}
