import { getPrimaryColor } from "@/utils/hooks"
import {
  Box,
  Group,
  type MantineStyleProp,
  useMantineTheme,
} from "@mantine/core"
import { IconChevronRight } from "@tabler/icons-react"
import { Link, type LinkProps } from "@tanstack/react-router"

type RowOptionProps = {
  type?: "basic" | "action"
  label: React.ReactNode | string
  description?: React.ReactNode | string
  prefix?: React.ReactNode | string
  suffix?: React.ReactNode | string
  href?: LinkProps["to"]
  params?: LinkProps["params"]
  search?: LinkProps["search"]
  icon?: React.ReactNode
  onClick?: () => void
}

export const RowOption: React.FC<RowOptionProps> = ({
  type = "basic",
  label,
  description,
  prefix,
  suffix,
  href,
  params,
  search,
  icon,
  onClick,
}) => {
  const theme = useMantineTheme()
  const hrefProps:
    | {
        component: React.ReactNode
        to: LinkProps["to"]
        params: LinkProps["params"]
        search?: LinkProps["search"]
      }
    | object = href ? { to: href, component: Link, params, search } : {}
  const themePrimaryColor = getPrimaryColor(theme)

  const actionStyles: MantineStyleProp =
    type === "action"
      ? {
          color: themePrimaryColor,
          cursor: "pointer",
        }
      : {}

  const hrefStyles: MantineStyleProp =
    href || onClick
      ? {
          cursor: "pointer",
        }
      : {}

  return (
    <Group
      px="md"
      py="xs"
      align="center"
      gap="xs"
      style={{
        userSelect: "none",
        WebkitTapHighlightColor: "transparent",
        textDecoration: "none",
        color: "var(--text-color)",
        flexWrap: "nowrap",
        ...actionStyles,
        ...hrefStyles,
      }}
      {...hrefProps}
      onClick={onClick}
    >
      {!!prefix && <Box display="inline-flex">{prefix}</Box>}
      <Box
        style={{
          flexGrow: 1,
        }}
      >
        {label}
        {!!description && (
          <Box fz="xs" c="dimmed">
            {description}
          </Box>
        )}
      </Box>
      {!!suffix && <Box display="inline-flex">{suffix}</Box>}
      {(!!href || onClick) && type === "basic" && (
        <Box display="inline-flex">
          {icon || <IconChevronRight size={20} stroke={2} opacity={0.4} />}
        </Box>
      )}
    </Group>
  )
}
