import {
  Grid,
  Group,
  type MantineColor,
  Progress,
  Text,
  useMantineColorScheme,
} from "@mantine/core"
import { IconCircleCheckFilled } from "@tabler/icons-react"

type BalanceBonusProps = {
  value: number
  color?: MantineColor
}

const BONUS_POINTS = [0, 500, 1000]

export const BalanceBonus: React.FC<BalanceBonusProps> = ({ value, color }) => {
  const { colorScheme } = useMantineColorScheme()
  const percent = (value / 1000) * 100

  const firstPercent = percent < 50 ? percent * 2 : 100
  const secondPercent = value >= BONUS_POINTS[1] ? (percent - 49) * 2 : 0
  const thirdPercent = value >= 1000 ? 100 : 0

  const progressBgColor =
    colorScheme === "light"
      ? "var(--mantine-color-gray-0)"
      : "var(--mantine-color-dark-8)"

  return (
    <Grid grow gutter={4} my="xs">
      <Grid.Col span={6} />
      <Grid.Col span={3}>
        <Text
          size="xs"
          ta="center"
          style={{
            transform: "translateX(-50%)",
          }}
        >
          500₽
        </Text>
      </Grid.Col>
      <Grid.Col span={3}>
        <Text
          size="xs"
          ta="center"
          style={{
            transform: "translateX(-50%)",
          }}
        >
          1 000₽
        </Text>
      </Grid.Col>
      <Grid.Col span={6}>
        <Progress
          size="sm"
          transitionDuration={0}
          value={firstPercent}
          color={color}
        />
        <Group gap={2} h={24} align="center">
          {!secondPercent && (
            <Text size="xs">До бонуса не хватает {500 - value}₽</Text>
          )}
        </Group>
      </Grid.Col>
      <Grid.Col span={3}>
        <Progress
          size="sm"
          transitionDuration={0}
          value={secondPercent}
          color={color}
        />
        <Group gap={2} h={24} align="center">
          {secondPercent > 0 && (
            <Text c="green" lh={1}>
              <IconCircleCheckFilled size={16} />
            </Text>
          )}
          <Text size="xs" c={secondPercent > 0 ? "green" : undefined}>
            +15% бонус
          </Text>
        </Group>
      </Grid.Col>
      <Grid.Col span={3}>
        <Progress
          size="sm"
          transitionDuration={0}
          value={thirdPercent}
          color={color}
        />
        <Group gap={2} h={24} align="center">
          {thirdPercent > 0 && (
            <Text c="green" lh={1}>
              <IconCircleCheckFilled size={16} />
            </Text>
          )}
          <Text size="xs" c={thirdPercent > 0 ? "green" : undefined}>
            +20% бонус
          </Text>
        </Group>
      </Grid.Col>
    </Grid>
  )
}
