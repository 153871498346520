import type { BaseConnectionProps } from "@/components/data/connect/BaseConnection"
import type { App } from "@/services/apps/apps"
import { Accordion, Badge, Group } from "@mantine/core"
import type { InboundEntityExternal } from "@vpnox/sdk"
import { Suspense, lazy } from "react"

import classes from "./AppsAccordion.module.css"

type AppsAccordionProps = {
  apps: App[]
  inbound: InboundEntityExternal
}

const getConnectionComponent = (props: BaseConnectionProps) => {
  switch (props.value.name) {
    case "Invisible Man": {
      const InvisibleManApp = lazy(() =>
        import("@/components/data/connect/apps/InvisibleManApp").then(
          module => ({
            default: module.InvisibleManApp,
          }),
        ),
      )

      return <InvisibleManApp {...props} />
    }

    default: {
      const BaseConnection = lazy(() =>
        import("@/components/data/connect/BaseConnection").then(module => ({
          default: module.BaseConnection,
        })),
      )

      return <BaseConnection {...props} />
    }
  }
}

export const AppsAccordion: React.FC<AppsAccordionProps> = ({
  apps,
  inbound,
}) => {
  return (
    <Accordion classNames={classes}>
      {apps?.map(({ icon, ...data }) => (
        <Accordion.Item key={data.name} value={data.name}>
          <Accordion.Control icon={icon}>
            <Group justify="space-between" px={10}>
              {data.name}

              <Group>
                {data.favorite && <Badge size="md">Рекомендуем</Badge>}
              </Group>
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            <Suspense fallback={<>...</>}>
              {getConnectionComponent({
                value: data,
                inbound: inbound,
              })}
            </Suspense>
          </Accordion.Panel>
        </Accordion.Item>
      ))}
    </Accordion>
  )
}
