import { components } from "@/theme/overrides"
import { createTheme, rem } from "@mantine/core"

export const theme = createTheme({
  components,
  fontFamily:
    '"Onest Variable", system-ui, -apple-system, BlinkMacSystemFont, "Roboto", "Apple Color Emoji", "Helvetica Neue", sans-serif',
  headings: {
    fontWeight: "700",
    sizes: {
      h1: {
        fontSize: rem(20),
        lineHeight: rem(26),
      },
      h2: {
        fontSize: rem(18),
        lineHeight: rem(26),
      },
      h3: {
        fontSize: rem(16),
        lineHeight: rem(24),
      },
      h4: {
        fontSize: rem(14),
        lineHeight: rem(22),
      },
    },
  },
  black: "#0e0e11",
  colors: {
    dark: [
      "#fafafa",
      "#f4f4f5",
      "#e4e4e7",
      "#d4d4d8",
      "#a1a1aa",
      "#71717a",
      "#52525b",
      "#3f3f46",
      "#27272a",
      "#1a1a1e",
      "#18181b",
      "#09090b",
    ],
  },
  // fontSizes: {
  //   xs: "calc(0.75rem * var(--mantine-scale))",
  //   sm: "calc(0.875rem * var(--mantine-scale))",
  //   md: "calc(1rem * var(--mantine-scale))",
  //   lg: "calc(1.125rem * var(--mantine-scale))",
  //   xl: "calc(1.25rem * var(--mantine-scale))",
  // },
})
