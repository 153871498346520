import type React from "react"
import { APPS_JSON } from "./contants"

import HiddifyIcon from "@/assets/apps/hiddify.svg"
import ShadowrocketIcon from "@/assets/apps/shadowrocket.svg"
import SingboxIcon from "@/assets/apps/singbox.svg"
import Streisand from "@/assets/apps/streisand.svg"
import V2BoxIcon from "@/assets/apps/v2box.svg"
import V2RayNGIcon from "@/assets/apps/v2rayng.svg"

import IconAndroid from "@/assets/systems/android.svg"
import IconApple from "@/assets/systems/ios.svg"
import IconWindows from "@/assets/systems/windows.svg"

export {
  Streisand,
  V2BoxIcon,
  SingboxIcon,
  ShadowrocketIcon,
  HiddifyIcon,
  V2RayNGIcon,
  IconApple,
  IconAndroid,
  IconWindows,
}

export type AppData = Readonly<{
  name: string
  links: {
    name: string
    url: string
    version?: string
  }[]
  connectUrlMask: string
  favorite: boolean
}>

export type App = AppData & {
  icon?: React.ReactElement
}

const iconProps = {
  style: {
    width: 32,
    height: 32,
  },
}

export const IOS_APPS: App[] = [
  {
    icon: <Streisand {...iconProps} />,
    ...APPS_JSON.IOS.Streisand,
  },
  {
    icon: <V2BoxIcon {...iconProps} />,
    ...APPS_JSON.IOS.V2Box,
  },
  {
    icon: <SingboxIcon {...iconProps} />,
    ...APPS_JSON.IOS.SingBox,
  },
  {
    icon: <ShadowrocketIcon {...iconProps} />,
    ...APPS_JSON.IOS.Shadowrocket,
  },
]

export const ANDROID_APPS: App[] = [
  {
    icon: <HiddifyIcon {...iconProps} />,
    ...APPS_JSON.Android.Hiddify,
  },
  {
    icon: <V2BoxIcon {...iconProps} />,
    ...APPS_JSON.Android.V2Box,
  },
  {
    icon: <V2RayNGIcon {...iconProps} />,
    ...APPS_JSON.Android.v2rayNG,
  },
  {
    icon: <SingboxIcon {...iconProps} />,
    ...APPS_JSON.Android.SingBox,
  },
]

export const WINDOWS_APPS: App[] = [
  {
    icon: <HiddifyIcon {...iconProps} />,
    ...APPS_JSON.Windows.Hiddify,
  },
  {
    icon: <IconWindows {...iconProps} />,
    ...APPS_JSON.Windows.InvisibleMan,
  },
]

export const MACOS_APPS: App[] = [
  {
    icon: <HiddifyIcon {...iconProps} />,
    ...APPS_JSON.MacOS.Hiddify,
  },
  {
    icon: <Streisand {...iconProps} />,
    ...APPS_JSON.MacOS.Streisand,
  },
]
