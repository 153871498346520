import { DefaultCatchBoundary } from "@/components/layouts/DefaultCatchBoundary"
import { DefaultLayout } from "@/components/layouts/DefaultLayout"
import { isDev } from "@/utils/dev"
import { useMantineColorScheme, useMantineTheme } from "@mantine/core"
import { captureException } from "@sentry/react"
import type { QueryClient } from "@tanstack/react-query"
import { Outlet, createRootRouteWithContext } from "@tanstack/react-router"
import {
  type RGB,
  bindMiniAppCSSVars,
  bindThemeParamsCSSVars,
  bindViewportCSSVars,
  useMiniApp,
  useSwipeBehavior,
  useThemeParams,
  useViewport,
} from "@telegram-apps/sdk-react"
import React, { useEffect } from "react"

export type RouterContext = {
  queryClient: QueryClient
}

export const Route = createRootRouteWithContext<RouterContext>()({
  component: RootComponent,
  onError: error => {
    console.error("onError", error)
    captureException(error)
  },
  onCatch: error => {
    captureException(error)
    console.error("onCatch", error)
  },
  errorComponent: errorProps => (
    <DefaultLayout>
      <DefaultCatchBoundary {...errorProps} />
    </DefaultLayout>
  ),
})

function RootComponent() {
  const miniApp = useMiniApp()
  const themeParams = useThemeParams()
  const viewport = useViewport()
  const theme = useMantineTheme()
  const { colorScheme } = useMantineColorScheme()

  const swipeBehavior = useSwipeBehavior()

  useEffect(() => {
    return bindMiniAppCSSVars(miniApp, themeParams)
  }, [miniApp, themeParams])

  // TODO: redirect to settings page
  // const settingsButton = useSettingsButton()
  // useEffect(() => {
  //   settingsButton.show()
  // }, [settingsButton])

  useEffect(() => {
    return bindThemeParamsCSSVars(themeParams)
  }, [themeParams])

  useEffect(() => {
    return viewport && bindViewportCSSVars(viewport)
  }, [viewport])

  useEffect(() => {
    if (colorScheme === "dark") {
      miniApp.setBgColor(theme.black as RGB)
      miniApp.setHeaderColor(theme.black as RGB)
    } else {
      miniApp.setBgColor(theme.colors.gray[1] as RGB)
      miniApp.setHeaderColor(theme.colors.gray[1] as RGB)
    }

    if (swipeBehavior.supports("disableVerticalSwipe")) {
      swipeBehavior.disableVerticalSwipe()
    }

    if (viewport && !viewport.isExpanded) {
      viewport.expand()
    }
  }, [viewport, theme, colorScheme, miniApp, swipeBehavior])

  // Enable debug mode to see all the methods sent and events received.
  useEffect(() => {
    if (isDev) {
      import("eruda").then(lib => lib.default.init())
    }
  }, [])

  return (
    <DefaultLayout>
      <Outlet />
    </DefaultLayout>
  )
}
