import type { NotificationData as MantineNotificationData } from "@mantine/notifications"
import { notifications } from "@mantine/notifications"

type NotificationData = Pick<MantineNotificationData, "title" | "message">

export const useNotifications = () => {
  const showSuccessMessage = (data: NotificationData) =>
    notifications.show({
      ...data,
      color: "green",
    })

  const showErrorMessage = (data: NotificationData) =>
    notifications.show({
      ...data,
      color: "red",
    })

  const showInfoMessage = (data: NotificationData) =>
    notifications.show({
      ...data,
      color: "cyan",
    })

  return {
    showSuccessMessage,
    showErrorMessage,
    showInfoMessage,
  }
}
