/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * VPNoX API
 * OpenAPI spec version: 1.0
 */
import { useQuery, useSuspenseQuery } from "@tanstack/react-query"
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from "@tanstack/react-query"
import { customInstance } from "../../client"
import type { ErrorType } from "../../client"
import type { UserEntity } from "../../model"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

export const usersControllerLoginTelegram = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<UserEntity>(
    { url: "/api/users/me", method: "GET", signal },
    options,
  )
}

export const getUsersControllerLoginTelegramQueryKey = () => {
  return ["/api/users/me"] as const
}

export const getUsersControllerLoginTelegramQueryOptions = <
  TData = Awaited<ReturnType<typeof usersControllerLoginTelegram>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof usersControllerLoginTelegram>>,
    TError,
    TData
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getUsersControllerLoginTelegramQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof usersControllerLoginTelegram>>
  > = ({ signal }) => usersControllerLoginTelegram(requestOptions, signal)

  return {
    queryKey,
    queryFn,
    staleTime: 300000,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof usersControllerLoginTelegram>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type UsersControllerLoginTelegramQueryResult = NonNullable<
  Awaited<ReturnType<typeof usersControllerLoginTelegram>>
>
export type UsersControllerLoginTelegramQueryError = ErrorType<unknown>

export function useUsersControllerLoginTelegram<
  TData = Awaited<ReturnType<typeof usersControllerLoginTelegram>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof usersControllerLoginTelegram>>,
    TError,
    TData
  >
  request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getUsersControllerLoginTelegramQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getUsersControllerLoginTelegramSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof usersControllerLoginTelegram>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof usersControllerLoginTelegram>>,
    TError,
    TData
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getUsersControllerLoginTelegramQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof usersControllerLoginTelegram>>
  > = ({ signal }) => usersControllerLoginTelegram(requestOptions, signal)

  return {
    queryKey,
    queryFn,
    staleTime: 300000,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof usersControllerLoginTelegram>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type UsersControllerLoginTelegramSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof usersControllerLoginTelegram>>
>
export type UsersControllerLoginTelegramSuspenseQueryError = ErrorType<unknown>

export function useUsersControllerLoginTelegramSuspense<
  TData = Awaited<ReturnType<typeof usersControllerLoginTelegram>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof usersControllerLoginTelegram>>,
    TError,
    TData
  >
  request?: SecondParameter<typeof customInstance>
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getUsersControllerLoginTelegramSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}
