export function plural(
  n: number,
  one: string | ((n: number) => string),
  two: string | ((n: number) => string),
  five: string | ((n: number) => string),
  zero?: string | ((n: number) => string),
): string {
  if (n === 0 && zero !== undefined) {
    return typeof zero === "function" ? zero(n) : zero
  }

  if (n % 10 === 1 && n % 100 !== 11) {
    return typeof one === "function" ? one(n) : `${n} ${one}`
  }

  if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) {
    return typeof two === "function" ? two(n) : `${n} ${two}`
  }

  return typeof five === "function" ? five(n) : `${n} ${five}`
}
