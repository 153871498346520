import { RemainingDays } from "@/components/alerts/RemainingDays"
import { ButtonVertical } from "@/components/buttons/ButtonVertical/ButtonVertical"
import { BalanceCard } from "@/components/data/BalanceCard"
import { StickyBox } from "@/components/layouts/StickyBox"
import { BasicCard } from "@/components/shared/BasicCard/BasicCard"
import { useAlertStore } from "@/stores/alert"
import { plural } from "@/utils/plural"
import {
  Grid,
  Group,
  type MantineSize,
  Progress,
  Stack,
  Text,
} from "@mantine/core"
import { IconSquareRoundedPlus } from "@tabler/icons-react"
import { useSuspenseQuery } from "@tanstack/react-query"
import { getInboundsControllerFindAllQueryOptions } from "@vpnox/sdk"
import { getUsersControllerLoginTelegramQueryOptions } from "@vpnox/sdk"

export const Dashboard = () => {
  const { data: user, isLoading: userIsLoading } = useSuspenseQuery(
    getUsersControllerLoginTelegramQueryOptions(),
  )
  const { data: inbounds, isLoading: inboundsIsLoading } = useSuspenseQuery(
    getInboundsControllerFindAllQueryOptions(),
  )
  const { checkLastDateBalanceClosedActive } = useAlertStore()

  const devicesPercent = inbounds.data?.length
    ? Math.ceil((inbounds.data.length / user.inboundLimit) * 100)
    : 0

  const depth: MantineSize = "md"
  const limit = plural(
    inbounds.data?.length || 0,
    n => `${n} устройство из ${user.inboundLimit}`,
    n => `${n} устройства из ${user.inboundLimit}`,
    n => `${n} устройств из ${user.inboundLimit}`,
  )

  return (
    <StickyBox component={Grid} grow>
      <Grid.Col span={12}>
        <BalanceCard
          user={user}
          loading={userIsLoading}
          withLink
          depth={depth}
        />
      </Grid.Col>
      <Grid.Col span={8}>
        <BasicCard
          depth={depth}
          content
          style={{
            height: "100%",
            flex: 1,
          }}
        >
          <Stack
            justify="space-between"
            style={{
              flex: 1,
            }}
          >
            <Group
              style={{
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Text
                style={theme => ({
                  fontWeight: 700,
                  fontSize: theme.fontSizes.lg,
                  textTransform: "uppercase",
                })}
              >
                Тариф
              </Text>
              <Text
                style={theme => ({
                  fontWeight: 700,
                  fontSize: theme.fontSizes.lg,
                })}
              >
                {user.amountPerDay} ₽/день
              </Text>
            </Group>

            <Stack gap={4}>
              <Text c="dimmed" size="sm">
                {limit}
              </Text>
              <Progress
                color={user.remainingDays > 0 ? "green" : "orange"}
                value={devicesPercent}
              />
            </Stack>
          </Stack>
        </BasicCard>
      </Grid.Col>
      <Grid.Col span={4}>
        <ButtonVertical
          href={"/payment"}
          icon={<IconSquareRoundedPlus size={24} strokeWidth={2} />}
        >
          Пополнить баланс
        </ButtonVertical>
      </Grid.Col>
      {user.remainingDays > 0 &&
        user.remainingDays <= 3 &&
        checkLastDateBalanceClosedActive(user.remainingDays) && (
          <Grid.Col span={12}>
            <RemainingDays days={user.remainingDays} />
          </Grid.Col>
        )}
    </StickyBox>
  )
}
