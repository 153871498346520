/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * VPNoX API
 * OpenAPI spec version: 1.0
 */

export type InboundEntityStatus =
  (typeof InboundEntityStatus)[keyof typeof InboundEntityStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InboundEntityStatus = {
  active: "active",
  disabled: "disabled",
} as const
