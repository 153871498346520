import { STORE_DATE_FORMAT } from "@/utils/date"
import dayjs from "dayjs"
import { createStore } from "zustand-x"

const ALERT_STORE = "alerts"

export type AlertStore = {
  lastDateBalanceClosed: string | null
}

const alertStore = createStore(ALERT_STORE)<AlertStore>(
  {
    lastDateBalanceClosed: null,
  },
  {
    persist: {
      enabled: true,
      name: ALERT_STORE,
      version: 1.0,
    },
  },
)

export const useAlertStore = () => {
  const lastDateBalanceClosed = alertStore.use.lastDateBalanceClosed()
  const setLastDateBalanceClosed = () =>
    alertStore.set.lastDateBalanceClosed(dayjs().format(STORE_DATE_FORMAT))

  const checkLastDateBalanceClosedActive = (remainingDays: number) =>
    lastDateBalanceClosed
      ? dayjs().isAfter(
          dayjs(lastDateBalanceClosed, STORE_DATE_FORMAT).add(1, "day"),
        )
      : remainingDays <= 3

  return {
    lastDateBalanceClosed,
    setLastDateBalanceClosed,
    checkLastDateBalanceClosedActive,
  }
}
