export function findNextDivisibleByFour(num: number): number {
  // Если число уже делится на 4, возвращаем его
  if (num % 4 === 0) {
    return num
  }

  // Округляем число вверх до ближайшего целого
  const roundedUp = Math.ceil(num)

  // Находим остаток от деления на 4
  const remainder = roundedUp % 4

  // Если остаток 0, значит roundedUp уже делится на 4
  if (remainder === 0) {
    return roundedUp
  }

  // Иначе добавляем разницу до следующего числа, делимого на 4
  return roundedUp + (4 - remainder)
}
