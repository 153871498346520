import { Divider } from "@mantine/core"

import classes from "./divider.module.css"

export default {
  Divider: Divider.extend({
    classNames: classes,
    defaultProps: {
      color: "gray.4",
    },
  }),
}
