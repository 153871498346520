import { RowOption } from "@/components/RowOption"
import {
  BasicCard,
  type BasicCardProps,
} from "@/components/shared/BasicCard/BasicCard"
import { IconBlock } from "@/components/shared/IconBlock"
import { IconShare2 } from "@tabler/icons-react"
import { useSuspenseQuery } from "@tanstack/react-query"
import { useUtils } from "@telegram-apps/sdk-react"
import { getUsersControllerLoginTelegramQueryOptions } from "@vpnox/sdk"

export const ShareButton = (props: BasicCardProps) => {
  const utils = useUtils()
  const {
    data: { telegramId },
  } = useSuspenseQuery(getUsersControllerLoginTelegramQueryOptions())

  return (
    <BasicCard py={4} {...props}>
      <RowOption
        prefix={<IconBlock icon={<IconShare2 size={22} />} />}
        label={"Поделиться приложением"}
        description={"Это поможет нам стать лучше"}
        onClick={() => {
          utils.shareURL(
            `https://t.me/vpnox_bot?ref=${telegramId}`,
            "Я использую VPNoX, присоединяйся! 🚀",
          )
        }}
      />
    </BasicCard>
  )
}
