import { Card, type CardProps, type MantineSize } from "@mantine/core"
import clsx from "clsx"
import type { PropsWithChildren } from "react"

import classes from "./BasicCard.module.css"

export type BasicCardProps = PropsWithChildren &
  CardProps & {
    depth?: MantineSize
    content?: boolean
  }

export const BasicCard: React.FC<BasicCardProps> = ({
  children,
  depth = "sm",
  content = false,
  ...props
}) => {
  return (
    <Card
      {...props}
      padding={0}
      className={clsx(
        classes.root,
        props.className,
        content && classes.padding,
      )}
      data-depth={depth}
    >
      {children}
    </Card>
  )
}
