import { Alert } from "@mantine/core"
import { IconAlertCircle } from "@tabler/icons-react"
import { useSuspenseQuery } from "@tanstack/react-query"
import { getUsersControllerLoginTelegramQueryOptions } from "@vpnox/sdk"

export const DeactivatedByBalance: React.FC = () => {
  const {
    data: { remainingDays },
  } = useSuspenseQuery(getUsersControllerLoginTelegramQueryOptions())

  if (remainingDays > 0) {
    return null
  }

  return (
    <Alert variant="light" color="orange" icon={<IconAlertCircle />}>
      Устройство отключено, пополните баланс
    </Alert>
  )
}
