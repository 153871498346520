import { Box, type BoxProps } from "@mantine/core"

import classes from "./IconBlock.module.css"

type IconBlockProps = {
  icon: React.ReactNode | string
  size?: number
} & BoxProps

export const IconBlock: React.FC<IconBlockProps> = ({
  icon,
  size = 38,
  ...props
}) => (
  <Box w={size} h={size} className={classes.root} {...props}>
    {icon}
  </Box>
)
