import { useMDXComponents } from "@/components/mdx-elements"
import { TypographyStylesProvider } from "@mantine/core"
import { MDXProvider } from "@mdx-js/react"
import { Outlet, createFileRoute } from "@tanstack/react-router"

export const Route = createFileRoute("/_mdx")({
  component: MdxComponent,
})

function MdxComponent() {
  const components = useMDXComponents()

  return (
    <MDXProvider components={components}>
      <TypographyStylesProvider flex={1}>
        <Outlet />
      </TypographyStylesProvider>
    </MDXProvider>
  )
}
