import { BasicCard } from "@/components/shared/BasicCard"
import {
  Box,
  Button,
  List,
  Space,
  Stack,
  TextInput,
  ThemeIcon,
  rem,
} from "@mantine/core"
import { useForm } from "@mantine/form"
import { IconCircleCheck } from "@tabler/icons-react"
import { zodResolver } from "mantine-form-zod-resolver"
import type React from "react"
import { z } from "zod"

const deviceCreateSchema = z.object({
  name: z.string().min(2, { message: "Name should have at least 2 letters" }),
})

export type DeviceCreateFormValues = z.infer<typeof deviceCreateSchema>

export type DeviceCreateFormProps = {
  onSubmit: (values: DeviceCreateFormValues) => void
  loading?: boolean
}

export const DeviceCreateForm: React.FC<DeviceCreateFormProps> = ({
  onSubmit: handleSubmit,
  loading,
}) => {
  const form = useForm<DeviceCreateFormValues>({
    mode: "uncontrolled",
    initialValues: {
      name: "Устройство 1",
    },
    validate: zodResolver(deviceCreateSchema),
  })

  return (
    <form
      onSubmit={form.onSubmit(handleSubmit)}
      style={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TextInput
        label="Название"
        description="Оно будет доступно только вам в личном кабинете"
        placeholder="Имя устройства"
        withAsterisk
        autoFocus
        {...form.getInputProps("name")}
      />

      <Space />
      <Stack mt="auto">
        <BasicCard content>
          <List
            size="sm"
            spacing="xs"
            icon={
              <ThemeIcon color="teal" size={24} radius="xl">
                <IconCircleCheck style={{ width: rem(16), height: rem(16) }} />
              </ThemeIcon>
            }
          >
            <List.Item>
              Для каждого устройства доступно 4 страны: Дания, Нидерланды, США,
              Россия
            </List.Item>
            <List.Item>
              Все сервера имеют высокоскоростное интернет соединение от 2 гбит/с
            </List.Item>
            <List.Item>
              На всех серверах установлен AdGuard для блокировки рекламы
            </List.Item>
            <List.Item>
              При необходимости наша поддержка поможет вам с настройкой и
              ответит на все вопросы
            </List.Item>
          </List>
        </BasicCard>
        <Box
          style={{
            position: "sticky",
            bottom: 0,
          }}
        >
          <Button loading={loading} type="submit">
            Добавить устройство
          </Button>
        </Box>
      </Stack>
    </form>
  )
}
