import { Box, type BoxProps, createPolymorphicComponent } from "@mantine/core"
import { forwardRef } from "react"

// type StickyBoxProps = {
//   children: React.ReactNode
// } & BoxProps

interface StickyBoxProps extends BoxProps {
  children: React.ReactNode
}

export const StickyBox = createPolymorphicComponent<"button", StickyBoxProps>(
  forwardRef<HTMLDivElement, StickyBoxProps>(({ children, ...props }, ref) => (
    <Box
      px="md"
      pb="md"
      pt="xs"
      style={{
        position: "sticky",
        top: 0,
        zIndex: 10,
      }}
      {...props}
      ref={ref}
    >
      {children}
    </Box>
  )),
)

// export const StickyBox = ({ children, ...props }: StickyBoxProps) => {
//   return (
//     <Box
//       px="md"
//       pb="md"
//       pt="xs"
//       style={{
//         position: "sticky",
//         top: HEADER_HEIGHT,
//         zIndex: 10,
//       }}
//       {...props}
//     >
//       {children}
//     </Box>
//   )
// }
