import { HEADER_HEIGHT, Header } from "@/components/layouts/Header"
import { RefreshLayout } from "@/components/layouts/RefreshLayout"
import { isDev } from "@/utils/dev"
import { Box, ScrollArea, rem } from "@mantine/core"
import { useQueryClient } from "@tanstack/react-query"
import { TanStackRouterDevtools } from "@tanstack/router-devtools"
import { useViewport } from "@telegram-apps/sdk-react"
import { type PropsWithChildren, useEffect, useState } from "react"

export const DefaultLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const queryClient = useQueryClient()
  const vp = useViewport()
  const [viewportHeight, setViewportHeight] = useState(vp?.height || 0)

  const onRefreshRequested = async () => {
    await queryClient.invalidateQueries()
  }

  useEffect(() => {
    if (!vp) return

    const unsubscribe = vp.on("change:stableHeight", () => {
      setViewportHeight(vp.stableHeight)
    })

    return () => {
      unsubscribe()
    }
  }, [vp])

  return (
    <>
      <ScrollArea
        h={"var(--tg-viewport-stable-height, 100vh)"}
        style={{
          transition: "height 0.23s ease-in-out",
          display: "flex",
          flexGrow: 1,
        }}
        type="never"
      >
        <RefreshLayout onRefreshRequested={onRefreshRequested}>
          <Header />
          <Box
            style={{
              maxWidth: 400,
              minHeight: viewportHeight,
              margin: "0 auto",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              paddingTop: rem(HEADER_HEIGHT),
            }}
          >
            {children}
          </Box>
        </RefreshLayout>
      </ScrollArea>
      {isDev && <TanStackRouterDevtools position="bottom-left" />}
      <Box
        h={"calc(100vh - var(--tg-viewport-stable-height))"}
        style={{
          transition: "height 0.23s ease",
          backgroundColor: "var(--card-xs)",
        }}
      />
    </>
  )
}
