import {
  ActionIcon,
  Group,
  useComputedColorScheme,
  useMantineColorScheme,
  useMantineTheme,
} from "@mantine/core"
import { IconMoon, IconSun } from "@tabler/icons-react"
import { type RGB, useMiniApp } from "@telegram-apps/sdk-react"
import cx from "clsx"
import classes from "./ButtonTheme.module.css"

export const ButtonTheme = () => {
  const miniApp = useMiniApp()
  const { setColorScheme } = useMantineColorScheme()
  const computedColorScheme = useComputedColorScheme("light", {
    getInitialValueInEffect: true,
  })
  const theme = useMantineTheme()

  const handleToggleColorScheme = () => {
    if (computedColorScheme === "light") {
      setColorScheme("dark")

      miniApp.setBgColor(theme.black as RGB)
      miniApp.setHeaderColor(theme.black as RGB)
    } else {
      setColorScheme("light")

      miniApp.setBgColor(theme.colors.gray[1] as RGB)
      miniApp.setHeaderColor(theme.colors.gray[1] as RGB)
    }
  }

  return (
    <Group justify="center">
      <ActionIcon
        onClick={handleToggleColorScheme}
        variant="subtle"
        color="gray"
        size="lg"
        aria-label="Toggle color scheme"
      >
        <IconSun className={cx(classes.icon, classes.light)} stroke={2} />
        <IconMoon className={cx(classes.icon, classes.dark)} stroke={2} />
      </ActionIcon>
    </Group>
  )
}
