import { queryClient } from "@/services/query-client"
import { createBrowserHistory, createRouter } from "@tanstack/react-router"

import { pageCaptureException } from "@/libs/sentry"
// Import the generated route tree
import { routeTree } from "./routeTree.gen"

const history = createBrowserHistory()

// Create a new router instance
export const router = createRouter({
  routeTree,
  history,
  context: {
    queryClient,
  },
  defaultOnCatch: error => {
    pageCaptureException(error)
  },
})

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router
  }
}
