import { NumberFormatter, Skeleton, Stack, Text } from "@mantine/core"
import type React from "react"

export const StatisticCardContent: React.FC<{
  balance?: number
  loading?: boolean
  subEndDate?: string | null
}> = ({ balance, loading = false, subEndDate }) => {
  return (
    <Stack gap={0}>
      {/*<Text c="dimmed" size="sm">*/}
      {/*  Ваш баланс*/}
      {/*</Text>*/}
      <Text fw={700} fz={32}>
        <NumberFormatter
          suffix=" ₽"
          value={balance ?? 0}
          thousandSeparator=" "
        />
      </Text>
      <Skeleton visible={loading}>
        {subEndDate === null ? (
          <Text c="orange.7" size="sm">
            Услуги приостановлены, пополните баланс
          </Text>
        ) : (
          <Text c="dimmed" size="sm">
            Средств хватит до {subEndDate}
          </Text>
        )}
      </Skeleton>
    </Stack>
  )
}
