import { RowOption } from "@/components/RowOption"
import { BasicCard } from "@/components/shared/BasicCard/BasicCard"
import { IconBlock } from "@/components/shared/IconBlock"
import { Paper, Skeleton, useMantineColorScheme } from "@mantine/core"
import { useSuspenseQuery } from "@tanstack/react-query"
import { getInboundsControllerFindAllQueryOptions } from "@vpnox/sdk"
import { Fragment } from "react"

export const InboundsList: React.FC = () => {
  const { data: inbounds, isLoading } = useSuspenseQuery(
    getInboundsControllerFindAllQueryOptions(),
  )
  const { colorScheme } = useMantineColorScheme()

  const isEmpty = !isLoading && inbounds?.data?.length === 0

  return (
    <BasicCard py={4}>
      {isLoading ? (
        <>
          <Skeleton height={60} radius={0} />
          <Skeleton height={60} radius={0} />
          <Skeleton height={60} radius={0} />
        </>
      ) : isEmpty ? (
        <Paper
          style={theme => ({
            padding: theme.spacing.md,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: theme.fontSizes.sm,
            color: "var(--mantine-color-dimmed)",
            backgroundColor: "transparent",
            height: 120,
          })}
        >
          У вас пока нет устройств
        </Paper>
      ) : (
        inbounds?.data?.map(inbound => (
          <Fragment key={inbound.id}>
            <RowOption
              prefix={
                <IconBlock
                  icon={"🤖"}
                  style={theme => ({
                    backgroundColor: "var(--mantine-color-gray-light)",
                  })}
                />
              }
              label={inbound.name}
              description={
                inbound.status === "active" ? "Активно" : "Отключено"
              }
              href={"/devices/$id"}
              params={{ id: inbound.id }}
            />
          </Fragment>
        ))
      )}
    </BasicCard>
  )
}
