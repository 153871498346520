import { HelpButton } from "@/components/buttons/HelpButton"
import { ShareButton } from "@/components/buttons/ShareButton"
import { InboundsList } from "@/components/data/InboundsList"
import { useRouterBackButton } from "@/libs/navigation"
import { Button, Space, Stack, Title } from "@mantine/core"
import { IconSquareRoundedPlus } from "@tabler/icons-react"
import { useSuspenseQuery } from "@tanstack/react-query"
import { Link, Outlet, createFileRoute } from "@tanstack/react-router"
import {
  getInboundsControllerFindAllQueryOptions,
  getUsersControllerLoginTelegramQueryOptions,
} from "@vpnox/sdk"
import type React from "react"

export const Route = createFileRoute("/_dashboard/")({
  loader: ({ context: { queryClient } }) => {
    queryClient.ensureQueryData(getUsersControllerLoginTelegramQueryOptions())
    queryClient.ensureQueryData(getInboundsControllerFindAllQueryOptions())
  },
  component: DashboardComponent,
})

function DashboardComponent() {
  const {
    data: { inboundLimit },
  } = useSuspenseQuery(getUsersControllerLoginTelegramQueryOptions())
  const { data: inbounds } = useSuspenseQuery(
    getInboundsControllerFindAllQueryOptions(),
  )

  const isMaxInbounds = (inbounds.data?.length || 0) >= inboundLimit

  useRouterBackButton(true)

  return (
    <>
      <Title>Ваши устройства</Title>
      <InboundsList />
      <Button
        component={Link}
        leftSection={
          !isMaxInbounds && <IconSquareRoundedPlus size={24} strokeWidth={2} />
        }
        to={"/devices/create"}
        disabled={isMaxInbounds}
      >
        {!isMaxInbounds ? "Добавить устройство" : "Достигнут лимит устройств"}
      </Button>
      <Space />
      <Stack mt="auto">
        <HelpButton />
        <ShareButton />
      </Stack>
      <Outlet />
    </>
  )
}
