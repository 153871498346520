import { useRouterBackButton } from "@/libs/navigation"
import { useShallowEffect } from "@mantine/hooks"
import { createStore } from "zustand-x"

type PageStore = {
  title: string
}

const pageStore = createStore("page")<PageStore>({
  title: "",
})

export const useTitle = () => {
  const title = pageStore.use.title()
  const setTitle = (title: string) => pageStore.set.title(title)

  // TODO: Remove this line
  useRouterBackButton()

  useShallowEffect(() => {
    return () => {
      setTitle("")
    }
  }, [])

  return { title, setTitle }
}
