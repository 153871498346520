import { Input, NumberInput } from "@mantine/core"

import classes from "./inputs.module.css"

export default {
  Input: Input.extend({
    defaultProps: {
      size: "md",
      radius: "md",
      classNames: classes,
    },
  }),
  TextInput: Input.extend({
    defaultProps: {
      size: "md",
      radius: "md",
      classNames: classes,
    },
  }),
  NumberInput: NumberInput.extend({
    defaultProps: {
      size: "md",
      radius: "md",
      classNames: classes,
      hideControls: true,
      allowDecimal: false,
      allowNegative: false,
    },
  }),
}
