import { ActionIcon, Button } from "@mantine/core"

import classes from "./buttons.module.css"

export default {
  Button: Button.extend({
    defaultProps: {
      size: "md",
      radius: "md",
      fullWidth: true,
      classNames: classes,
    },
  }),
  ActionIcon: ActionIcon.extend({
    defaultProps: {
      radius: "md",
      size: "md",
      classNames: classes,
    },
  }),
}
