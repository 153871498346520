import { Box, Button, Space, Stack, TextInput } from "@mantine/core"
import { useForm } from "@mantine/form"
import { zodResolver } from "mantine-form-zod-resolver"
import type React from "react"
import { z } from "zod"
// import data from "@emoji-mart/data"
// import Picker from "@emoji-mart/react"

const deviceEditSchema = z.object({
  name: z.string().min(2, { message: "Name should have at least 2 letters" }),
})

export type DeviceEditFormValues = z.infer<typeof deviceEditSchema>

export type DeviceEditFormProps = {
  onSubmit: (values: DeviceEditFormValues) => void
  defaultValues?: DeviceEditFormValues
  loading?: boolean
}

export const DeviceEditForm: React.FC<DeviceEditFormProps> = ({
  onSubmit: handleSubmit,
  defaultValues,
  loading,
}) => {
  const form = useForm<DeviceEditFormValues>({
    mode: "uncontrolled",
    initialValues: {
      name: defaultValues?.name || "Устройство 1",
    },
    validate: zodResolver(deviceEditSchema),
  })

  return (
    <Box
      component={"form"}
      onSubmit={form.onSubmit(handleSubmit)}
      style={{ flex: 1, display: "flex", flexDirection: "column" }}
    >
      <Stack>
        <TextInput
          label="Название"
          placeholder="Имя устройства"
          withAsterisk
          autoFocus
          {...form.getInputProps("name")}
        />
        {/*<BasicCard title="Эмоджи">*/}
        {/*  <Picker*/}
        {/*    data={data}*/}
        {/*    categories={[*/}
        {/*      "people",*/}
        {/*      "nature",*/}
        {/*      "foods",*/}
        {/*      "activity",*/}
        {/*      "places",*/}
        {/*      "objects",*/}
        {/*    ]}*/}
        {/*    emojiSize={32}*/}
        {/*    emojiButtonSize={52}*/}
        {/*    locale="ru"*/}
        {/*    maxFrequentRows={0}*/}
        {/*    perLine={8}*/}
        {/*    previewPosition="none"*/}
        {/*    skinTonePosition="none"*/}
        {/*    onEmojiSelect={console.log}*/}
        {/*  />*/}
        {/*</BasicCard>*/}
      </Stack>
      <Space />
      <Box mt="auto">
        <Button loading={loading} type="submit">
          Сохранить
        </Button>
      </Box>
    </Box>
  )
}
