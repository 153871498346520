import { MainContent } from "@/components/layouts/MainContent"
import { Outlet, createFileRoute } from "@tanstack/react-router"

export const Route = createFileRoute("/_page")({
  component: PageComponent,
})

function PageComponent() {
  return (
    <MainContent>
      <Outlet />
    </MainContent>
  )
}
