import { Button, Loader, Modal, Stack, Text } from "@mantine/core"
import { IconCircleCheckFilled, IconCircleXFilled } from "@tabler/icons-react"
import { createFileRoute } from "@tanstack/react-router"
import { useClosingBehavior, useHapticFeedback } from "@telegram-apps/sdk-react"
import {
  type ResponsePaymentStatusDtoStatus,
  getPaymentsControllerValidatePaymentQueryOptions,
  usePaymentsControllerValidatePayment,
} from "@vpnox/sdk"
import { useEffect, useState } from "react"

export const Route = createFileRoute("/_page/payment/progress/$id")({
  component: PaymentProgressComponent,
})

const WaitingComponent = ({ onClose }: { onClose: () => void }) => (
  <>
    <Stack gap="lg" align="center" justify="center" flex={1}>
      <Loader size={42} />
      <Text ta="center">Идет проверка платежа</Text>
    </Stack>
    <Text ta="center" size="sm" c="dimmed">
      Вы можете дождаться ее окончания или перейти на главную страницу. Деньги
      будут зачислены после оплаты автоматически.
    </Text>
    <Button variant="light" onClick={onClose}>
      Вернуться на главную
    </Button>
  </>
)

const SuccessComponent = ({ onClose }: { onClose: () => void }) => (
  <>
    <Stack gap="lg" align="center" justify="center" flex={1}>
      <Text c={"green"}>
        <IconCircleCheckFilled size={80} />
      </Text>
      <Text ta="center">Платеж успешно выполнен</Text>
    </Stack>
    <Button onClick={onClose}>Вернуться на главную</Button>
  </>
)

const ErrorComponent = ({ onClose }: { onClose: () => void }) => (
  <>
    <Stack gap="lg" align="center" justify="center" flex={1}>
      <Text c={"red"}>
        <IconCircleXFilled size={80} />
      </Text>
      <Text ta="center">Платеж не выполнен</Text>
    </Stack>
    <Text ta="center" size="sm" c="dimmed">
      Возможно, произошла ошибка при оплате или платеж был отменен. Попробуйте
      еще раз.
    </Text>
    <Button onClick={onClose}>Вернуться на главную</Button>
  </>
)

function PaymentProgressComponent() {
  const [opened, setOpened] = useState(true)
  const navigate = Route.useNavigate()
  const { queryClient } = Route.useRouteContext()
  const { id } = Route.useParams()
  const [refetchInterval, setRefetchInterval] = useState(5000)
  const [status, setStatus] =
    useState<ResponsePaymentStatusDtoStatus>("pending")
  const hapticFeedback = useHapticFeedback()
  const closingBehavior = useClosingBehavior()

  useEffect(() => {
    closingBehavior.enableConfirmation()

    return () => closingBehavior.disableConfirmation()
  }, [closingBehavior])

  const queryOptions = getPaymentsControllerValidatePaymentQueryOptions(id)
  const { data } = usePaymentsControllerValidatePayment(id, {
    query: {
      ...queryOptions,
      refetchInterval,
    },
  })

  useEffect(() => {
    if (!data) {
      return
    }

    setStatus(data.status)

    if (data.status === "succeeded") {
      setRefetchInterval(0)
      hapticFeedback.notificationOccurred("success")
    } else if (data.status === "canceled") {
      setRefetchInterval(0)
      hapticFeedback.notificationOccurred("error")
    }
  }, [data, hapticFeedback])

  const onClose = () => setOpened(false)

  const handleCloseModal = async () => {
    await queryClient.invalidateQueries()

    void navigate({
      to: "/",
      replace: true,
      resetScroll: true,
    })
  }

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      closeOnEscape={false}
      withCloseButton={false}
      transitionProps={{
        onExited: handleCloseModal,
      }}
    >
      <Stack align="center" justify="space-between" h={300}>
        {status === "succeeded" ? (
          <SuccessComponent onClose={onClose} />
        ) : status === "canceled" ? (
          <ErrorComponent onClose={onClose} />
        ) : (
          <WaitingComponent onClose={onClose} />
        )}
      </Stack>
    </Modal>
  )
}
