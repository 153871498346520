import { queryCaptureException } from "@/libs/sentry"
import { useNotifications } from "@/utils/hooks/use-notifications"
import { MutationCache, QueryCache, QueryClient } from "@tanstack/react-query"
import axios from "axios"

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      const { showErrorMessage } = useNotifications()
      const { queryKey } = query

      if (axios.isAxiosError(error)) {
        showErrorMessage({
          title: "Произошла ошибка",
          message: error.response?.data.message || error.message,
        })
      } else {
        showErrorMessage({
          title: "Произошла ошибка",
          message: "Мы уже уведомлены, пожалуйста, попробуйте позже",
        })

        // call sentry
        queryCaptureException({
          error,
          type: "query",
          key: queryKey,
        })
      }
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, variables, __, mutation) => {
      const { showErrorMessage } = useNotifications()
      const { mutationKey } = mutation.options

      if (axios.isAxiosError(error)) {
        showErrorMessage({
          title: "Произошла ошибка",
          message: error.response?.data.message || error.message,
        })
      } else {
        // call sentry or other error tracking service
        console.log("API Mutation Error", mutationKey, variables, error)
        showErrorMessage({
          title: "Произошла ошибка",
          message: "Мы уже уведомлены, пожалуйста, попробуйте позже",
        })

        queryCaptureException({
          error,
          type: "mutation",
          key: mutationKey,
          variables,
        })
      }
    },
  }),
  defaultOptions: {
    queries: {
      retry: false,
    },
    mutations: {
      retry: false,
    },
  },
})
