import { retrieveLaunchParams } from "@telegram-apps/sdk"
import Axios, { type AxiosError, type AxiosRequestConfig } from "axios"

export const AXIOS_INSTANCE = Axios.create({
  // @ts-ignore
  baseURL: import.meta.env.VITE_API_URL,
})

// Add a request interceptor to the Axios instance
AXIOS_INSTANCE.interceptors.request.use(
  config => {
    const { initDataRaw } = retrieveLaunchParams()

    config.headers = config.headers || {}
    config.headers.Authorization = `tma ${initDataRaw}`

    return config
  },
  error => {
    return Promise.reject(error)
  },
)

// add a second `options` argument here if you want to pass extra options to each generated query
export const customInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig,
): Promise<T> => {
  const source = Axios.CancelToken.source()
  const promise = AXIOS_INSTANCE({
    ...config,
    ...options,
    cancelToken: source.token,
  }).then(({ data }) => data)

  // @ts-ignore
  promise.cancel = () => {
    source.cancel("Query was cancelled")
  }

  return promise
}

export type ErrorType<Error> = AxiosError<Error>

export type BodyType<BodyData> = BodyData
