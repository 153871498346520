import { Notification } from "@mantine/core"
import { Notifications } from "@mantine/notifications"

import classes from "./notifications.module.css"

export default {
  Notifications: Notifications.extend({
    defaultProps: {
      position: "top-center",
      containerWidth: 400,
    },
  }),
  Notification: Notification.extend({
    defaultProps: {
      radius: "lg",
      classNames: classes,
    },
  }),
}
