import { ErrorComponent } from "@/components/layouts/ErrorComponent"
import { MainContent } from "@/components/layouts/MainContent"
import { Button, Group, Stack, Text } from "@mantine/core"
import {
  type ErrorComponentProps,
  Link,
  rootRouteId,
  useMatch,
  useRouter,
} from "@tanstack/react-router"
import { useLaunchParams } from "@telegram-apps/sdk-react"

export function DefaultCatchBoundary({ error }: ErrorComponentProps) {
  const router = useRouter()
  const isRoot = useMatch({
    strict: false,
    select: state => state.id === rootRouteId,
  })

  const launchParams = useLaunchParams()

  console.error(error)

  return (
    <MainContent>
      <ErrorComponent error={error} />
      <Stack mt="auto">
        <Button
          variant="light"
          onClick={() => {
            router.invalidate()
          }}
        >
          Попробовать еще раз
        </Button>
        {isRoot ? (
          <Button component={Link} to="/">
            На главную
          </Button>
        ) : (
          <Button
            component={Link}
            to="/"
            onClick={e => {
              e.preventDefault()
              window.history.back()
            }}
          >
            На главную
          </Button>
        )}
      </Stack>
      <Group justify="center">
        <Text c="dimmed" size="xs">
          Platform: {launchParams.platform}
        </Text>
        <Text c="dimmed" size="xs">
          Telegram API Version: {launchParams.version}
        </Text>
      </Group>
    </MainContent>
  )
}
