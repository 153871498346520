import {
  Group,
  type RadioCardProps as MantineRadioCardProps,
  Radio,
} from "@mantine/core"

import classes from "./RadioCard.module.css"

type RadioCardProps = {
  value: string
  children?: React.ReactNode
} & MantineRadioCardProps

export const RadioCard: React.FC<RadioCardProps> = ({
  value,
  children,
  ...others
}) => {
  return (
    <Radio.Card value={value} key={value} {...others} classNames={classes}>
      <Group wrap="nowrap" align="flex-start">
        {/*<Radio.Indicator />*/}
        {children}
      </Group>
    </Radio.Card>
  )
}
