import { useShallowEffect } from "@mantine/hooks"
import { useRouter } from "@tanstack/react-router"
import type { BrowserNavigator } from "@telegram-apps/sdk"
import { useBackButton } from "@telegram-apps/sdk-react"
import { useEffect } from "react"

export function useIntegration<State>(nav: BrowserNavigator<State>) {
  // When Mini Apps navigator changes its location, we should actualize the reactive values.
  useEffect(() => {
    return nav.on("change", console.log)
  }, [nav])
}

export const useRouterBackButton = (hide = false) => {
  const backButton = useBackButton()
  const { history, ...other } = useRouter()

  useShallowEffect(() => {
    if (hide) {
      backButton.hide()
      return
    }

    backButton.show()

    const unsub = backButton.on("click", async () => {
      history.back()
    })

    return () => {
      unsub()
    }
  }, [history, backButton])

  return backButton
}
