import Logo from "@/assets/logo.svg"
import { ButtonTheme } from "@/components/buttons/ButtonTheme"
import { CornerPath } from "@/components/shared/svg/CornerPath"
import { useTitle } from "@/stores/page"
import {
  Avatar,
  Box,
  Group,
  Loader,
  Title,
  Transition,
  rem,
} from "@mantine/core"
import { useIsFetching, useIsMutating } from "@tanstack/react-query"
import { Link, useRouterState } from "@tanstack/react-router"
import { useUsersControllerLoginTelegramSuspense } from "@vpnox/sdk"
import type { PropsWithChildren } from "react"

import classes from "./Header.module.css"

export const HEADER_HEIGHT = 54

const HeaderMask: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      {children}
      <CornerPath
        size={16 * 0.92}
        fill={"var(--mantine-color-body)"}
        style={{
          position: "absolute",
          top: "100%",
          left: 0,
        }}
      />
      <CornerPath
        rtl
        size={16 * 0.92}
        fill={"var(--mantine-color-body)"}
        style={{
          position: "absolute",
          top: "100%",
          right: 0,
        }}
      />
    </>
  )
}

const RouterSpinner = () => {
  const isFetching = useIsFetching()
  const isMutating = useIsMutating()
  const isRouteLoading = useRouterState({
    select: s => s.status === "pending",
  })

  const isLoading = Boolean(isFetching) || Boolean(isMutating) || isRouteLoading

  return (
    <Transition mounted={isLoading} transition="fade">
      {transitionStyle => (
        <Loader
          color="gray"
          size="xs"
          style={{ ...transitionStyle, zIndex: 1 }}
        />
      )}
    </Transition>
  )
}

const slideLeft = {
  in: { opacity: 1, transform: "translateX(0)" },
  out: { opacity: 0, transform: "translateX(1rem)" },
  common: { transformOrigin: "right" },
  transitionProperty: "transform, opacity",
}

export const Header = () => {
  const { data: user, isLoading: userIsLoading } =
    useUsersControllerLoginTelegramSuspense()
  const { title } = useTitle()

  const name = `${user?.firstName} ${user?.lastName}`.trim()

  return (
    <Box
      component={"header"}
      className={classes.header}
      style={{
        height: HEADER_HEIGHT,
        maxWidth: 400,
        margin: "0 auto",
      }}
    >
      <HeaderMask>
        <Group justify="space-between">
          <Group gap="xs" flex={1}>
            {!title ? (
              <Avatar
                name={name}
                color="initials"
                size="sm"
                radius="xl"
                alt={name}
              />
            ) : (
              <Link to="/">
                <Avatar
                  size="sm"
                  radius="xl"
                  alt="VPNoX"
                  classNames={{
                    root: classes.logo,
                    placeholder: classes.placeholder,
                  }}
                >
                  <Logo
                    style={{
                      width: rem(16),
                      height: rem(16),
                    }}
                  />
                </Avatar>
              </Link>
            )}
            <Title className={classes.title}>{title || name}</Title>
          </Group>

          <Group>
            <RouterSpinner />
            <ButtonTheme />
          </Group>
        </Group>
      </HeaderMask>
    </Box>
  )
}
