/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * VPNoX API
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery, useSuspenseQuery } from "@tanstack/react-query"
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from "@tanstack/react-query"
import { customInstance } from "../../client"
import type { BodyType, ErrorType } from "../../client"
import type {
  CreateInboundDto,
  InboundEntity,
  InboundsControllerFindAll200AllOf,
  UpdateInboundDto,
} from "../../model"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

export const inboundsControllerCreate = (
  createInboundDto: BodyType<CreateInboundDto>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<InboundEntity>(
    {
      url: "/api/inbounds",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createInboundDto,
    },
    options,
  )
}

export const getInboundsControllerCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof inboundsControllerCreate>>,
    TError,
    { data: BodyType<CreateInboundDto> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof inboundsControllerCreate>>,
  TError,
  { data: BodyType<CreateInboundDto> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof inboundsControllerCreate>>,
    { data: BodyType<CreateInboundDto> }
  > = props => {
    const { data } = props ?? {}

    return inboundsControllerCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type InboundsControllerCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof inboundsControllerCreate>>
>
export type InboundsControllerCreateMutationBody = BodyType<CreateInboundDto>
export type InboundsControllerCreateMutationError = ErrorType<unknown>

export const useInboundsControllerCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof inboundsControllerCreate>>,
    TError,
    { data: BodyType<CreateInboundDto> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof inboundsControllerCreate>>,
  TError,
  { data: BodyType<CreateInboundDto> },
  TContext
> => {
  const mutationOptions = getInboundsControllerCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const inboundsControllerFindAll = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<InboundsControllerFindAll200AllOf>(
    { url: "/api/inbounds", method: "GET", signal },
    options,
  )
}

export const getInboundsControllerFindAllQueryKey = () => {
  return ["/api/inbounds"] as const
}

export const getInboundsControllerFindAllQueryOptions = <
  TData = Awaited<ReturnType<typeof inboundsControllerFindAll>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof inboundsControllerFindAll>>,
    TError,
    TData
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getInboundsControllerFindAllQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof inboundsControllerFindAll>>
  > = ({ signal }) => inboundsControllerFindAll(requestOptions, signal)

  return {
    queryKey,
    queryFn,
    staleTime: 300000,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof inboundsControllerFindAll>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type InboundsControllerFindAllQueryResult = NonNullable<
  Awaited<ReturnType<typeof inboundsControllerFindAll>>
>
export type InboundsControllerFindAllQueryError = ErrorType<unknown>

export function useInboundsControllerFindAll<
  TData = Awaited<ReturnType<typeof inboundsControllerFindAll>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof inboundsControllerFindAll>>,
    TError,
    TData
  >
  request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getInboundsControllerFindAllQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getInboundsControllerFindAllSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof inboundsControllerFindAll>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof inboundsControllerFindAll>>,
    TError,
    TData
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getInboundsControllerFindAllQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof inboundsControllerFindAll>>
  > = ({ signal }) => inboundsControllerFindAll(requestOptions, signal)

  return {
    queryKey,
    queryFn,
    staleTime: 300000,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof inboundsControllerFindAll>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type InboundsControllerFindAllSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof inboundsControllerFindAll>>
>
export type InboundsControllerFindAllSuspenseQueryError = ErrorType<unknown>

export function useInboundsControllerFindAllSuspense<
  TData = Awaited<ReturnType<typeof inboundsControllerFindAll>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof inboundsControllerFindAll>>,
    TError,
    TData
  >
  request?: SecondParameter<typeof customInstance>
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getInboundsControllerFindAllSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const inboundsControllerFindOne = (
  id: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<InboundEntity>(
    { url: `/api/inbounds/${id}`, method: "GET", signal },
    options,
  )
}

export const getInboundsControllerFindOneQueryKey = (id: string) => {
  return [`/api/inbounds/${id}`] as const
}

export const getInboundsControllerFindOneQueryOptions = <
  TData = Awaited<ReturnType<typeof inboundsControllerFindOne>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof inboundsControllerFindOne>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getInboundsControllerFindOneQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof inboundsControllerFindOne>>
  > = ({ signal }) => inboundsControllerFindOne(id, requestOptions, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    staleTime: 300000,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof inboundsControllerFindOne>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type InboundsControllerFindOneQueryResult = NonNullable<
  Awaited<ReturnType<typeof inboundsControllerFindOne>>
>
export type InboundsControllerFindOneQueryError = ErrorType<unknown>

export function useInboundsControllerFindOne<
  TData = Awaited<ReturnType<typeof inboundsControllerFindOne>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof inboundsControllerFindOne>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getInboundsControllerFindOneQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getInboundsControllerFindOneSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof inboundsControllerFindOne>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof inboundsControllerFindOne>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getInboundsControllerFindOneQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof inboundsControllerFindOne>>
  > = ({ signal }) => inboundsControllerFindOne(id, requestOptions, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    staleTime: 300000,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof inboundsControllerFindOne>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type InboundsControllerFindOneSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof inboundsControllerFindOne>>
>
export type InboundsControllerFindOneSuspenseQueryError = ErrorType<unknown>

export function useInboundsControllerFindOneSuspense<
  TData = Awaited<ReturnType<typeof inboundsControllerFindOne>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof inboundsControllerFindOne>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getInboundsControllerFindOneSuspenseQueryOptions(
    id,
    options,
  )

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const inboundsControllerUpdate = (
  id: string,
  updateInboundDto: BodyType<UpdateInboundDto>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<InboundEntity>(
    {
      url: `/api/inbounds/${id}`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: updateInboundDto,
    },
    options,
  )
}

export const getInboundsControllerUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof inboundsControllerUpdate>>,
    TError,
    { id: string; data: BodyType<UpdateInboundDto> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof inboundsControllerUpdate>>,
  TError,
  { id: string; data: BodyType<UpdateInboundDto> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof inboundsControllerUpdate>>,
    { id: string; data: BodyType<UpdateInboundDto> }
  > = props => {
    const { id, data } = props ?? {}

    return inboundsControllerUpdate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type InboundsControllerUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof inboundsControllerUpdate>>
>
export type InboundsControllerUpdateMutationBody = BodyType<UpdateInboundDto>
export type InboundsControllerUpdateMutationError = ErrorType<unknown>

export const useInboundsControllerUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof inboundsControllerUpdate>>,
    TError,
    { id: string; data: BodyType<UpdateInboundDto> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof inboundsControllerUpdate>>,
  TError,
  { id: string; data: BodyType<UpdateInboundDto> },
  TContext
> => {
  const mutationOptions = getInboundsControllerUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const inboundsControllerRemove = (
  id: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/api/inbounds/${id}`, method: "DELETE" },
    options,
  )
}

export const getInboundsControllerRemoveMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof inboundsControllerRemove>>,
    TError,
    { id: string },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof inboundsControllerRemove>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof inboundsControllerRemove>>,
    { id: string }
  > = props => {
    const { id } = props ?? {}

    return inboundsControllerRemove(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type InboundsControllerRemoveMutationResult = NonNullable<
  Awaited<ReturnType<typeof inboundsControllerRemove>>
>

export type InboundsControllerRemoveMutationError = ErrorType<unknown>

export const useInboundsControllerRemove = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof inboundsControllerRemove>>,
    TError,
    { id: string },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof inboundsControllerRemove>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions = getInboundsControllerRemoveMutationOptions(options)

  return useMutation(mutationOptions)
}
