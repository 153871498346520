import {
  DeviceCreateForm,
  type DeviceCreateFormValues,
} from "@/components/forms/DeviceCreateForm"
import { useTitle } from "@/stores/page"
import { useShallowEffect } from "@mantine/hooks"
import { useQueryClient } from "@tanstack/react-query"
import { createFileRoute } from "@tanstack/react-router"
import { useClosingBehavior } from "@telegram-apps/sdk-react"
import {
  getInboundsControllerFindAllQueryKey,
  useInboundsControllerCreate,
} from "@vpnox/sdk"
import { useEffect } from "react"

export const Route = createFileRoute("/_page/devices/create")({
  component: DevicesCreateComponent,
})

function DevicesCreateComponent() {
  const { setTitle } = useTitle()
  const navigate = Route.useNavigate()
  const closingBehavior = useClosingBehavior()

  useEffect(() => {
    closingBehavior.enableConfirmation()

    return () => closingBehavior.disableConfirmation()
  }, [closingBehavior])

  useShallowEffect(() => {
    setTitle("Новое устройство")
  }, [setTitle])

  const queryClient = useQueryClient()
  const { mutateAsync: createInbound, isPending } = useInboundsControllerCreate(
    {
      mutation: {
        mutationKey: ["inbounds", "create"],
        async onSuccess(newInbound) {
          // TODO: uncomment this when the backend will return the created inbound
          // queryClient.setQueryData(
          //   getInboundsControllerFindOneQueryKey(newInbound.id),
          //   newInbound,
          // )

          // invalidate all the list queries
          await queryClient.invalidateQueries({
            queryKey: getInboundsControllerFindAllQueryKey(),
          })
        },
      },
    },
  )

  const handleSubmit = async (values: DeviceCreateFormValues) => {
    try {
      const inbound = await createInbound({
        data: values,
      })

      void navigate({
        to: "/devices/$id/success",
        params: {
          id: inbound.id,
        },
        replace: true,
      })
    } catch (error) {
      console.error(error)
    }
  }

  return <DeviceCreateForm onSubmit={handleSubmit} loading={isPending} />
}
