import { RowOption } from "@/components/RowOption"
import { StatisticCardContent } from "@/components/StatisticCardContent"
import { BasicCard } from "@/components/shared/BasicCard/BasicCard"
import type { MantineSize } from "@mantine/core"
import type { UserEntity } from "@vpnox/sdk"
import dayjs from "dayjs"
import type React from "react"
import { useMemo } from "react"

type BalanceCardProps = {
  user?: UserEntity
  withLink?: boolean
  depth?: MantineSize
  loading?: boolean
}

export const BalanceCard: React.FC<BalanceCardProps> = ({
  user,
  withLink = false,
  depth,
}) => {
  // const subDays = useMemo(() => {
  //   if (!user) {
  //     return 0
  //   }
  //
  //   return user.balance >= user.amountPerDay
  //     ? Math.floor(user.balance / user.amountPerDay)
  //     : 0
  // }, [user])

  const subEndDate = useMemo(() => {
    if (!user) {
      return null
    }

    return user.remainingDays > 0
      ? dayjs().add(user.remainingDays, "day").format("DD MMMM YYYY")
      : null
  }, [user])

  return (
    <BasicCard depth={depth}>
      <RowOption
        label={
          <StatisticCardContent
            balance={user?.balance}
            subEndDate={subEndDate}
          />
        }
        href={withLink ? "/balance" : undefined}
      />
    </BasicCard>
  )
}
