import { Dashboard } from "@/components/data/Dashboard"
import { MainContent } from "@/components/layouts/MainContent"
import { Outlet, createFileRoute } from "@tanstack/react-router"

export const Route = createFileRoute("/_dashboard")({
  component: DashboardComponent,
})

function DashboardComponent() {
  return (
    <>
      <Dashboard />

      <MainContent>
        <Outlet />
      </MainContent>
    </>
  )
}
