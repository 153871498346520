import alert from "./alert"
import buttons from "./buttons"
import card from "./card"
import divider from "./divider"
import grid from "./grid"
import group from "./group"
import inputs from "./inputs"
import modal from "./modal"
import notifications from "./notifications"
import progress from "./progress"
import space from "./space"
import stack from "./stack"

export const components = {
  ...alert,
  ...buttons,
  ...grid,
  ...group,
  ...card,
  ...divider,
  ...modal,
  ...notifications,
  ...progress,
  ...inputs,
  ...space,
  ...stack,
}
