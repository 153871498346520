type CornerPathProps = {
  size?: number
  fill?: string
  rtl?: boolean
  style?: React.CSSProperties
}

export const CornerPath: React.FC<CornerPathProps> = ({
  size = 20,
  fill = "#D9D9D9",
  rtl = false,
  style = {},
}) => {
  return (
    // biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      {!rtl ? (
        <path
          d={`M0 0H0V${size}C0 ${size / 2} ${size / 2} 0 ${size} 0Z`}
          fill={fill}
        />
      ) : (
        <path
          d={`M0 0C${size / 2} 0 ${size} ${size / 2} ${size} ${size}V0H0Z`}
          fill={fill}
        />
      )}
    </svg>
  )
}
