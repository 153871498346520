import { useTitle } from "@/stores/page"
import { Box, Button, Image, Space, Stack, Text, Title } from "@mantine/core"
import { useShallowEffect } from "@mantine/hooks"
import { useSuspenseQuery } from "@tanstack/react-query"
import { Link, createFileRoute } from "@tanstack/react-router"
import { getInboundsControllerFindOneQueryOptions } from "@vpnox/sdk"
import React from "react"

export const Route = createFileRoute("/_page/devices/$id/success")({
  loader: ({ context: { queryClient }, params: { id } }) => {
    queryClient.ensureQueryData(getInboundsControllerFindOneQueryOptions(id))
  },
  component: DevicesIdSuccessComponent,
})

function DevicesIdSuccessComponent() {
  const { id } = Route.useParams()
  const { setTitle } = useTitle()
  const { data: inbound, isLoading } = useSuspenseQuery(
    getInboundsControllerFindOneQueryOptions(id),
  )

  useShallowEffect(() => {
    setTitle(isLoading ? "Загрузка..." : inbound.name)
  }, [setTitle])

  return (
    <>
      <Stack gap="md" px="lg">
        <Box
          style={{
            height: 160,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            src="https://em-content.zobj.net/source/telegram/386/party-popper_1f389.webp"
            alt="Success"
            h={80}
            fit="contain"
          />
        </Box>

        <Title order={1} ta="center">
          Устройство добавлено
        </Title>

        <Text ta="center">
          Далее вы можете подключить ваше устройство к VPN
        </Text>

        <Space />
      </Stack>
      <Stack mt="auto">
        <Button component={Link} to="/" replace variant="light">
          Вернуться в главное меню
        </Button>

        <Button component={Link} to={"/devices/$id"} replace>
          Далее
        </Button>
      </Stack>
    </>
  )
}
