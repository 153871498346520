import { Alert, Button, Stack } from "@mantine/core"
import * as React from "react"

export function ErrorComponent({ error }: { error: any }) {
  const [show, setShow] = React.useState(process.env.NODE_ENV !== "production")

  return (
    <Alert title="Что-то пошло не так!" color="red">
      <Stack>
        <span>Уже сообщили нашему администратору, скоро починим</span>
        <div>
          <Button
            fullWidth={false}
            size="compact-xs"
            variant="outline"
            color="gray"
            onClick={() => setShow(d => !d)}
          >
            {show ? "Скрыть ошибку" : "Показать ошибку"}
          </Button>

          {show ? (
            <pre
              style={{
                fontSize: ".7em",
                border: "1px solid red",
                borderRadius: ".25rem",
                padding: ".3rem",
                color: "red",
                overflow: "auto",
                marginBottom: 0,
              }}
            >
              {error.message ? <code>{error.message}</code> : null}
            </pre>
          ) : null}
        </div>
      </Stack>
    </Alert>
  )
}
