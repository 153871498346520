import dayjs from "dayjs"
import "dayjs/locale/ru"

dayjs.locale("ru")

export const FULL_DATE_FORMAT = "DD MMMM YYYY, HH:mm"
export const DATE_FORMAT = "DD MMMM YYYY"
export const STORE_DATE_FORMAT = "YYYY-MM-DD"

export const formatDate = (
  date: string | Date,
  format: string = DATE_FORMAT,
) => {
  return dayjs(date).format(format)
}
