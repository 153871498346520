import { Card, Stack, Text } from "@mantine/core"
import { Link, type LinkProps } from "@tanstack/react-router"

import classes from "./ButtonVertical.module.css"

type ButtonVerticalProps = {
  icon?: React.ReactNode
  label?: string
  children?: React.ReactNode
  href: LinkProps["to"]
}

export const ButtonVertical: React.FC<ButtonVerticalProps> = ({
  icon,
  label,
  href,
  children,
}) => {
  return (
    <Card component={Link} to={href} className={classes.root}>
      <Stack gap="xs" align="center">
        {icon}
        <Text className={classes.label}>{label || children}</Text>
      </Stack>
    </Card>
  )
}
