import { StickyBox } from "@/components/layouts/StickyBox"
import { useNotifications } from "@/utils/hooks/use-notifications"
import {
  ActionIcon,
  Avatar,
  Button,
  Group,
  Indicator,
  Stack,
  Text,
  Title,
} from "@mantine/core"
import { modals } from "@mantine/modals"
import { IconPencil, IconTrash } from "@tabler/icons-react"
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query"
import { Link, useNavigate, useParams } from "@tanstack/react-router"
import {
  InboundEntityStatus,
  getInboundsControllerFindAllQueryKey,
  getInboundsControllerFindOneQueryOptions,
  useInboundsControllerRemove,
} from "@vpnox/sdk"

export const DeviceDashboard = () => {
  const { id } = useParams({
    from: "/_device/devices/$id",
  })
  const navigate = useNavigate({
    from: "/devices/$id",
  })
  const { data: inbound } = useSuspenseQuery(
    getInboundsControllerFindOneQueryOptions(id),
  )
  const { showSuccessMessage } = useNotifications()

  const queryClient = useQueryClient()
  const { mutateAsync: deleteInbound } = useInboundsControllerRemove({
    mutation: {
      async onSuccess() {
        void navigate({
          to: "/",
          replace: true,
        })

        // invalidate all the list queries
        await queryClient.invalidateQueries({
          queryKey: getInboundsControllerFindAllQueryKey(),
        })

        showSuccessMessage({
          title: "Выполнено",
          message: "Устройство успешно удалено",
        })
      },
    },
  })

  const openDeleteModal = () =>
    modals.openConfirmModal({
      title: "Удаление устройства",
      centered: true,
      children: (
        <Text size="sm">
          Вы уверены, что хотите удалить{" "}
          <Text component="span" fw="bold">
            {inbound.name}
          </Text>
          ? Это действие нельзя будет отменить
        </Text>
      ),
      labels: { confirm: "Удалить", cancel: "Отмена" },
      confirmProps: { color: "red" },
      onConfirm: () => deleteInbound({ id }),
      closeOnConfirm: true,
    })

  return (
    <StickyBox>
      <Stack>
        <Group>
          <Indicator
            color={
              inbound.status === InboundEntityStatus.active ? "teal" : "orange"
            }
            size={14}
            offset={4}
            withBorder
            processing={inbound.status === InboundEntityStatus.active}
          >
            <Avatar size="lg" display="inline-flex" radius="lg">
              🤖
            </Avatar>
          </Indicator>
          <Stack gap={0}>
            <Title order={1} mb={0}>
              {inbound.name}
            </Title>
            <Text c="dimmed" size="xs">
              #{inbound.id}
            </Text>
          </Stack>
        </Group>
        <Group>
          <Button
            component={Link}
            to={"/devices/$id/edit"}
            // @ts-ignore: fix this router issue types
            params={{ id }}
            style={{
              flex: 1,
            }}
            variant="light"
            leftSection={<IconPencil stroke={2} size={20} />}
          >
            Редактировать
          </Button>
          <ActionIcon
            variant="light"
            color="red"
            aria-label="Settings"
            w={48}
            onClick={openDeleteModal}
          >
            <IconTrash stroke={2} size={20} />
          </ActionIcon>
        </Group>
      </Stack>
    </StickyBox>
  )
}
