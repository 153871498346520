import {
  PaymentForm,
  type PaymentFormValues,
} from "@/components/forms/PaymentForm"
import { useTitle } from "@/stores/page"
import { useShallowEffect } from "@mantine/hooks"
import { Outlet, createFileRoute } from "@tanstack/react-router"
import {
  useClosingBehavior,
  usePopup,
  useUtils,
} from "@telegram-apps/sdk-react"
import {
  getUsersControllerLoginTelegramQueryOptions,
  usePaymentsControllerGenerateInvoice,
  useUsersControllerLoginTelegramSuspense,
} from "@vpnox/sdk"
import { useEffect, useState } from "react"

export const Route = createFileRoute("/_page/payment")({
  loader: ({ context: { queryClient } }) => {
    queryClient.ensureQueryData(getUsersControllerLoginTelegramQueryOptions())
  },
  component: PaymentComponent,
})

function PaymentComponent() {
  const { setTitle } = useTitle()
  const navigate = Route.useNavigate()
  const utils = useUtils()
  const closingBehavior = useClosingBehavior()
  const popup = usePopup()
  // const { queryClient } = Route.useRouteContext()

  useEffect(() => {
    closingBehavior.enableConfirmation()

    return () => closingBehavior.disableConfirmation()
  }, [closingBehavior])

  const {
    data: { remainingDays },
  } = useUsersControllerLoginTelegramSuspense()

  const { mutateAsync: createPaymentLink } =
    usePaymentsControllerGenerateInvoice()
  const [isLoading, setIsLoading] = useState(false)

  const handleCreatePayment = async (values: PaymentFormValues) => {
    try {
      setIsLoading(true)

      const { url, id } = await createPaymentLink({
        data: {
          amount: values.amount,
        },
      })

      if (!url) {
        setIsLoading(false)
        return console.error("Failed to create payment link")
      }

      setIsLoading(false)

      if (popup.supports("open")) {
        const result = await popup.open({
          title: "Оплата",
          message:
            "Вы будете перенаправлены на страницу оплаты.\nЕсли у вас возникнут проблемы напишите в нашу поддержку.",
          buttons: [
            {
              type: "destructive",
              text: "Отмена",
              id: "cancel",
            },
            {
              type: "default",
              text: "Оплатить",
              id: "ok",
            },
          ],
        })
        console.log(result)

        if (result === "cancel") {
          return
        }

        if (result === "ok") {
          // Start payment
          utils.openLink(url)

          setTimeout(() => {
            void navigate({
              to: `/payment/progress/${id}`,
              replace: true,
            })
          }, 500)
        }
      } else {
        // TODO: return link
        utils.openLink(url)
      }
    } catch (error) {
      setIsLoading(false)
      console.error(error)
    }
  }

  useShallowEffect(() => {
    setTitle("Пополнить баланс")
  }, [setTitle])

  return (
    <>
      <PaymentForm
        onSubmit={handleCreatePayment}
        loading={isLoading}
        remainingDays={remainingDays}
      />
      <Outlet />
    </>
  )
}
