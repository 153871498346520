import * as Sentry from "@sentry/react"
import type { MutationKey, QueryKey } from "@tanstack/react-query"

Sentry.init({
  enabled: !!import.meta.env.VITE_SENTRY_DSN,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/app\.vpnox\.pro\/api/],
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

export const queryCaptureException = ({
  error,
  type,
  key,
  variables,
}:
  | {
      error: Error
      type: "query"
      key: QueryKey | undefined
      variables?: any
    }
  | {
      error: Error
      type: "mutation"
      key: MutationKey | undefined
      variables?: any
    }) =>
  Sentry.captureException(error, s =>
    s
      .setExtra("type", type)
      .setExtra("key", key)
      .setExtra("variables", variables),
  )

export const pageCaptureException = (error: Error) =>
  Sentry.captureException(error)
