import { Modal, ScrollArea } from "@mantine/core"

import { IconX } from "@tabler/icons-react"
import classes from "./modal.module.css"

export default {
  Modal: Modal.extend({
    classNames: classes,
    defaultProps: {
      radius: "lg",
      withinPortal: true,
      padding: "md",
      size: 400,
      shadow: "md",
      scrollAreaComponent: ScrollArea,
      overlayProps: {
        backgroundOpacity: 0.7,
      },
      transitionProps: {
        duration: 200,
        transition: "slide-up",
      },
      closeButtonProps: {
        icon: <IconX size={20} strokeWidth={2.5} />,
      },
    },
  }),
}
